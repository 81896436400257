<script>
import GpsSceneMixin from '@/mixins/GpsScene'

export default {
  name: 'GpsSceneWithTargetMixin',
  mixins: [GpsSceneMixin],
  computed: {
    // Distance between position and target, in meters
    distanceToTarget () {
      if (!this.position || !this.target) {
        return null
      }
      const lat1 = this.position.coords.latitude
      const lon1 = this.position.coords.longitude
      const lat2 = this.target.lat
      const lon2 = this.target.long
      const R = 6378.137 // Radius of earth in KM
      const dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180
      const dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c
      return Math.round(d * 1000) // meters
    }
  },
  methods: {
    onPositionChangedAction () {
      console.log('* Distance to target = ', this.distanceToTarget)
      this.onDistanceUpdate(this.distanceToTarget)
    }
  }
}
</script>
