<template>
  <div class="zone" :style="zoneStyle">
    <component
      :is="item.componentName"
      v-for="item in items"
      :attributes="item.attributes"
      :key="item.number"
      :number="item.number"
      :ref="setItemRef"
    ></component>
  </div>
</template>

<script>
export default {
  name: 'LayoutZone',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    setItemRef (el) {
      if (el) {
        setTimeout(function () {
          el.$el.scrollIntoView({ behavior: 'smooth' })
        }, 50)
      }
    }
  },
  computed: {
    zone () {
      return this.$store.state.layout.getZone(this.name)
    },
    items () {
      const res = []
      for (const item of this.zone.components) {
        res.push({
          key: item.key,
          number: item.number,
          componentName: 'Scene' + item.key,
          attributes: item.attributes
        })
      }
      return res
    },
    background () {
      return this.zone.background
    },
    zoneStyle () {
      let res = ''
      if (this.$store.state.blurDisplay) {
        res += 'filter: blur(1.3px);'
      }

      if (!this.background) {
        return res
      }
      res += 'background: url("' + this.background + '");'

      const anchor = this.zone.backgroundAnchor
      if (anchor) {
        res += 'background-position: ' + anchor + ';'
      }
      res += 'background-size: auto 100%;'
      res += 'background-repeat: no-repeat;'
      res += 'height: 100%'
      return res
    }
  }
}
</script>

<style scoped lang="sass">
</style>
