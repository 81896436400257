<template>
  <div class="rich-text">
    <img
      :src="attributes.backgroundTop"
      id="image-top"
      class="bgimage"
    />
    <div
      class="content"
      :style="{'background-image': 'url(' + attributes.backgroundMiddle + ')'}">
      <div id="text"
      :style="{'font-family': attributes.font, 'font-size': attributes.size}"
      v-html="attributes.text">
      </div>
    </div>
    <img
      :src="attributes.backgroundBottom"
      class="bgimage"/>
  </div>
</template>

<script>
export default {
  name: 'SceneRichTextWithBackground',
  props: ['attributes']
}
</script>

<style lang="sass" scoped>

  .rich-text
    padding: 20px 0px

  #text
    padding: 0px 20px

  .content
    @media (max-width: 450px)
      word-break: break-word
      width: 80%
      box-sizing: content-box
      padding: 0px 10%
      display: block

    @media (min-width: 451px)
      padding: 0px 10%

    margin-top: -5px
    margin-bottom: -2px
    background-repeat: no-repeat repeat
    background-size: 100% auto
    background-clip: border-box

  .bgimage
    width: 100%
    z-index: 100

  #image-top
    z-index: 99

</style>
