<template>
  <div class="ticket">
    <div id="current-year" v-if="year.value && !timerActivated" :class="{'shake': year.shake}">
      {{ year.value }}
    </div>
    <div
      id="timer"
      v-if="timerActivated"
      :class="{'clue-modal-mode': displayCluesModal}"
    >
      <i class="far fa-clock"></i>
      <span>{{ timeElapsedStr }}</span>
    </div>
    <div id="current-energy" v-if="energy.value">
      <progress :class="energyProgressClasses" class="progress" :value="energy.value" max="100"></progress>
    </div>
    <div id="evidences-button" v-if="hasEvidences && displayEvidences" :style="evidencesButtonStyle">
      <button
        class="button is-default shake-light"
        :disabled="btnsDisabled"
        @click="showEvidencesPopup"
      >
        <span class="icon is-small">
          <i class="fas fa-list-check"></i>
        </span>
      </button>
    </div>
    <div id="clues-button" :class="{'hidden-clues-btn': nbClues === 0}">
      <button
        class="button is-default"
        :class="{'shake-light': nbClues > 0}"
        :disabled="btnsDisabled"
        @click="showCluesPopup"
      >
        <span class="icon is-small">
          <i class="far fa-lightbulb"></i>
        </span>
      </button>
    </div>
    <div id="top-right-buttons" v-if="(displayHuntings && huntings.length) || isGathering">
      <button
        v-if="isGathering"
        class="button is-default"
        @click="showRankingsPopup"
      >
        <span class="icon is-small">
          <i class="fas fa-ranking-star"></i>
        </span>
      </button>
      <span v-if="displayHuntings">
        <button
          class="button is-default"
          v-for="(hunting, index) in huntings"
          :key="'hunting' + index"
          @click="showHuntingModal(hunting.key)"
        >
          <img :src="hunting.icon"/>
          <span
            class="badge is-info"
            v-if="hunting.remainingToFind > 0"
          >
            {{ hunting.remainingToFind }}
          </span>
        </button>
      </span>
    </div>
    <div id="progression" v-if="!clues.length && progression > 0">
      {{ progression }}%
    </div>
    <div id="force-progress" v-if="sceneInProgress">
       <button
        class="button is-default"
        @click="forceProgress"
      >
        <span class="icon is-small">
          <i class="fas fa-angle-double-down"></i>
        </span>
      </button>
    </div>
    <HuntingModal
      :huntingKey="displayHuntingKey"
      v-model:displayed="displayHuntingModal"
      >
    </HuntingModal>
    <EvidencesModal v-model:displayed="displayEvidencesModal"></EvidencesModal>
    <CluesModal v-model:displayed="displayCluesModal"></CluesModal>
    <RankingsModal v-model:displayed="displayRankingsModal" v-if="isGathering"></RankingsModal>
    <div class="background" :style="backgroundStyle"></div>
    <div class="scene-content">
      <div v-if="isEmpty" id="empty-content-wait">
        {{ $t("common.loading") }}<br/><br/>
        <i class="fa-solid fa-circle-notch fa-spin"></i>
      </div>
      <component :is="layoutComponent"></component>
    </div>
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize'
import CluesModal from '@/components/CluesModal'
import HuntingModal from '@/components/hunting/HuntingModal'
import RankingsModal from '@/components/rankings/RankingsModal'
import EvidencesModal from '@/components/evidences/EvidencesModal'
import { formatElapsedTime } from '@/functions/time'

export default {
  name: 'TicketDisplay',
  components: { CluesModal, HuntingModal, RankingsModal, EvidencesModal },
  data () {
    return {
      displayCluesModal: false,
      displayRankingsModal: false,
      displayHuntingModal: false,
      displayEvidencesModal: false,
      displayHuntingKey: true,
      timeElapsedStr: ''
    }
  },
  created () {
    this.switchToTicket(this.ticketUid)
    setInterval(this.updateTimer.bind(this), 1000)
  },
  watch: {
    ticketUid (value) {
      this.switchToTicket(value)
    }
  },
  computed: {
    layoutComponent () {
      return 'Layout' + capitalize(this.$store.state.layout.key)
    },
    ticketUid () {
      return this.$route.params.ticketUid
    },
    background () {
      return this.$store.state.layout.background
    },
    clues () {
      return this.$store.state.clues
    },
    nbClues () {
      return this.clues.length
    },
    isFirstClues () {
      return this.$store.state.firstClues
    },
    isGathering () {
      return this.$store.getters.isGathering
    },
    displayEvidences () {
      return this.$store.state.displayEvidences
    },
    displayHuntings () {
      return this.$store.state.displayHuntings
    },
    sceneInProgress () {
      return this.$store.state.sceneItemsInProgress.length > 0
    },
    isEmpty () {
      return this.$store.state.itemNumber === -1
    },
    isDevModeEnabled () {
      return this.$store.state.devmode.enable
    },
    hasEvidences () {
      return this.$store.state.evidences.length > 0
    },
    evidencesButtonStyle () {
      if (this.isDevModeEnabled) {
        return {
          'margin-top': '30px'
        }
      }
      return {}
    },
    huntings () {
      const res = []
      for (const hunting of Object.values(this.$store.state.huntings)) {
        let remainingToFind = 0
        for (const huntingItem of hunting.items) {
          remainingToFind += huntingItem.target
          if (this.$store.state.huntings_found_items[huntingItem.key]) {
            remainingToFind -= Math.min(this.$store.state.huntings_found_items[huntingItem.key], huntingItem.target)
          }
        }
        res.push(
          {
            key: hunting.key,
            text: hunting.text,
            icon: hunting.icon,
            remainingToFind
          }
        )
      }
      return res
    },
    timerActivated () {
      return this.$store.state.timerStartedAt !== null
    },
    backgroundStyle () {
      let res = 'background-image: url("' + this.background + '");'

      let anchor = this.$store.state.layout.backgroundAnchor
      if (!anchor) {
        anchor = 'top center'
      }
      res += 'background-position: ' + anchor + ';'
      // res += 'background-size: cover;'
      res += 'background-repeat: no-repeat'
      return res
    },
    year () {
      return this.$store.state.year
    },
    energy () {
      return this.$store.state.energy
    },
    progression () {
      return Math.round(100 * this.$store.state.itemNumber / this.$store.state.lastItemNumber)
    },
    btnsDisabled () {
      return this.$store.state.updating
    },
    energyProgressClasses () {
      let res = ''
      if (this.energy.shake) {
        res = 'shake '
      }
      if (this.energy.value > 50) {
        res += 'is-success'
      } else if (this.energy.value >= 30) {
        res += 'is-warning'
      } else {
        res += 'is-danger'
      }
      return res
    }
  },
  methods: {
    switchToTicket (uid) {
      // Saving ticket uid into store
      this.$store.dispatch('switchToTicket', this.$route.params.ticketUid)
    },
    showCluesPopup () {
      this.displayCluesModal = true
    },
    showRankingsPopup () {
      this.displayRankingsModal = true
    },
    showEvidencesPopup () {
      this.displayEvidencesModal = true
    },
    showHuntingModal (key) {
      this.displayHuntingModal = true
      this.displayHuntingKey = key
    },
    updateTimer () {
      let timeElapsed = this.$store.state.baseTimeElapsed
      if (this.timerActivated) {
        timeElapsed += Math.abs(new Date() - this.$store.state.timerStartedAt) / 1000
      }
      this.timeElapsedStr = formatElapsedTime(timeElapsed)
    },
    forceProgress () {
      this.$store.dispatch('loadScene')
    }
  }
}
</script>

<style lang="sass">
.background
  position: fixed
  top: 0
  left: 0

  background-size: 100% auto
  @media (max-aspect-ratio: 1/1)
    background-size: auto 100%

  width: 100%
  height: 100%
  z-index: 2

.scene-content
  z-index: 100
  position: relative

#empty-content-wait
  margin-top: 200px
  .fa-circle-notch
    font-size: 60px

#back-button
  position: fixed
  top: 10px
  right: 10px
  z-index: 101

#progression
  position: fixed
  z-index: 101
  top: 5px
  right: 50%
  left: 50%
  opacity: 0.8
  font-size: 14px

#clues-button
  position: fixed
  z-index: 101
  bottom: 10px
  left: 10px
  .fa-lightbulb
    font-size: 24px

  &.hidden-clues-btn
    visibility: hidden

#top-right-buttons
  position: fixed
  z-index: 101
  top: 10px
  right: 10px
  button
    border-radius: 100px
    opacity: 0.7
    position: relative
    padding: 3px
    margin-left: 10px
    width: 35px
    height: 35px
    max-width: none
    .badge
      position: absolute
      top: -5px
      right: -6px
      font-size: 10px
      font-weight: bold
      z-index: 102
      border-radius: 50px
      background-color: #831f1d
      padding: 1px 5px
      color: white
  img
    width: 100%

#force-progress
  position: fixed
  z-index: 101
  bottom: 10px
  left: 50%
  right: 50%
  button
    border-radius: 100px
    opacity: 0.5
    padding: 3px
    width: 35px
    height: 35px
    max-width: none
    color: black

#current-year,#timer
  position: fixed
  font-weight: bold
  bottom: 10px

#current-year
  z-index: 101
  background-color: rgba(153, 127, 85, 0.4)
  color: white
  border: solid 1px white
  padding: 5px 10px
  right: 10px
  bottom: 10px

#evidences-button
  position: fixed
  z-index: 101
  top: 10px
  left: 0px
  button
    border-radius: 100px
    opacity: 0.7
    padding: 3px
    margin-left: 10px
    width: 35px
    height: 35px
    max-width: none

#timer
  z-index: 99999
  font-size: 19px
  right: 20px
  .fa-clock,span
    vertical-align: middle
  .fa-clock
    font-size: 25px
    padding-right: 10px
    padding-bottom: 5px
  &.clue-modal-mode
    color: white

.shake
  animation: shake 0.5s
  animation-iteration-count: 6

.shake-light
  animation: shake 0.5s
  animation-iteration-count: 4

#current-energy
  position: fixed
  z-index: 101
  bottom: 49px
  right: 10px
  progress
    opacity: 0.7
    margin: auto
    height: 8px
    width: 58px
    border-radius: 0px
    &.is-warning
      &::-moz-progress-bar
        background: rgba(220, 92, 0)
      &::-webkit-progress-value
        background: rgba(220, 92, 0)
      color: rgba(220, 92, 0, 0.7)

@keyframes shake
  0%
    transform: translate(1px, 1px) rotate(0deg)
  10%
    transform: translate(-1px, -2px) rotate(-1deg)
  20%
    transform: translate(-3px, 0px) rotate(1deg)
  30%
    transform: translate(3px, 2px) rotate(0deg)
  40%
    transform: translate(1px, -1px) rotate(1deg)
  50%
    transform: translate(-1px, 2px) rotate(-1deg)
  60%
    transform: translate(-3px, 1px) rotate(0deg)
  70%
    transform: translate(3px, 1px) rotate(-1deg)
  80%
    transform: translate(-1px, -1px) rotate(1deg)
  90%
    transform: translate(1px, 2px) rotate(0deg)
  100%
    transform: translate(1px, -2px) rotate(-1deg)
</style>
