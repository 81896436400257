<template>
  <div :style="wrapperStyle">
    <Zone name="main"></Zone>
  </div>
</template>

<script>
import Zone from '@/components/Zone'

export default {
  name: 'LayoutFullscreen-noscroll',
  components: { Zone },
  computed: {
    wrapperStyle () {
      const res = {}
      const paddingtop = this.$store.state.layout.attributes.paddingtop
      if (paddingtop === undefined) {
        res['padding-top'] = '0px'
      } else {
        res['padding-top'] = paddingtop
      }
      return res
    }
  }
}
</script>

<style scoped lang="sass">
  .zone
    position: fixed
    height: 100%
    width: 100%
    overflow: hidden
</style>
