<template>
  <div class="main-puzzle-container">
    <div
      class="puzzle-container"
      :style="puzzleContainerStyle"
    >
      <img
        class="puzzle-background"
        :src="attributes.background"
        @load="onImageLoad(index, $event)"/>
      <img
        class="added-layer"
        v-for="layerToDisplay in layersToDisplay"
        :style="layerToDisplay.style"
        :key="layerToDisplay.key"
        :src="layerToDisplay.url"/>
    </div>
    <div class="layer-selector-container">
      <div class="slide-to-left-shadow"></div>
      <div class="slide-to-right-shadow"></div>
      <div class="layer-selector">
        <img
          v-for="layer in availableLayers"
          :key="layer.data.url"
          :src="layer.data.url"
          @click="selectLayer(layer)"/>
      </div>
    </div>
    <div class="post-layer-selector" ref="postLayerSelector">
    </div>
    <div
      class="button is-default"
      v-if="isCorrect"
      @click="validate"
      :ref="onDisplaySuccessButton">
      {{ $t("common.congrats") }}&nbsp;<i class="fa fa-angle-right"></i>
    </div>
  </div>
</template>

<script>
import PuzzleMixin from '@/mixins/Puzzle'

export default {
  name: 'SceneLayeredPuzzle',
  mixins: [PuzzleMixin],
  props: ['attributes', 'number'],
  data () {
    return {
      imageWidth: 0,
      imageRatio: 1, // Ratio displayed comparing to original size (background)
      selectedLayers: {} // groupIndex: imageIndex
    }
  },
  mounted () {
    // Tweak to force scrolling down the puzzle
    const comp = this
    setTimeout(() => {
      comp.$refs.postLayerSelector.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  },
  computed: {
    // Transform the selected layers into a pile of layers with url and key
    layersToDisplay () {
      const res = {}
      let z = 200
      for (const [groupIndex, imageIndex] of Object.entries(this.selectedLayers)) {
        const layerStyle = {
          top: (this.attributes.location.y * this.imageRatio).toString() + 'px',
          left: (this.attributes.location.x * this.imageRatio).toString() + 'px',
          'z-index': z.toString(),
          transform: 'scale(' + this.imageRatio.toString() + ')',
          'transform-origin': 'top left'
        }

        res[groupIndex] = {
          url: this.attributes.layers[groupIndex][imageIndex].url,
          key: groupIndex.toString() + '-' + imageIndex.toString(),
          style: layerStyle
        }
        z += 1
      }
      return res
    },
    // Available layers
    availableLayers () {
      const res = []
      for (const [groupIndex, layerGroup] of this.attributes.layers.entries()) {
        for (const [layerIndex, layer] of layerGroup.entries()) {
          res.push({
            groupIndex,
            layerIndex,
            data: layer
          })
        }
      }
      return res
    },
    // Puzzle container style: set the image width once loaded
    puzzleContainerStyle () {
      if (this.imageWidth === 0) {
        return {}
      }
      return {
        width: this.imageWidth.toString() + 'px'
      }
    },
    // Return true if solution is correct
    isCorrect () {
      for (const [groupIndex, layerGroup] of this.attributes.layers.entries()) {
        if (this.selectedLayers[groupIndex] === undefined) {
          return false
        }
        const imageIndex = this.selectedLayers[groupIndex]
        if (!layerGroup[imageIndex].correct) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    // On loading image, retrieve the display scale ratio
    onImageLoad (index, evt) {
      this.imageRatio = evt.target.width / evt.target.naturalWidth
      this.imageWidth = evt.target.width
    },
    selectLayer (layer) {
      this.selectedLayers[layer.groupIndex] = layer.layerIndex
    },
    onDisplaySuccessButton (el) {
      if (el) {
        this.playVictorySound()
      }
      setTimeout(function () {
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    },
    validate () {
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    }
  }
}
</script>

<style scoped lang="sass">
  .puzzle-container
    margin: auto
    position: relative
    .puzzle-background
      display: block
      max-width: 100%
    img
      user-select: none

  .layer-selector-container
    position: relative
    height: 80px

    .slide-to-left-shadow, .slide-to-right-shadow
      position: absolute
      pointer-events: none
      width: 100px
      top: 0px
      bottom: 0px
    .slide-to-left-shadow
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0))
    .slide-to-right-shadow
      right: 0px
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0))

  .layer-selector
    display: flex
    height: 100%
    img
      display: block
      cursor: pointer
      user-select: none
      height: 100%
      width: auto
    overflow-x: scroll
    scroll-snap-type: x mandatory

  .added-layer
    position: absolute

  .post-layer-selector
    padding-top: 50px

  .button.is-default
    margin-bottom: 30px
</style>
