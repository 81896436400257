<template>
  <div class="help-permissions">
    <button type="button" class="button is-default" @click="move_back_to_ticket()">
      <i class="fa fa-chevron-left"></i>&nbsp; {{ $t("common.goBack") }}
    </button><br/><br/>

    {{ $t("help.iphoneAccelerometer.explanation") }}<br/><br/>

    <ol>
      <li>
        <b>{{ $t("help.iphoneAccelerometer.procedure1") }}</b>
        <img src="/img/help/iphone/iphone-accelerometer-1.jpg"/>
      </li>
      <li>
        <b>{{ $t("help.iphoneAccelerometer.procedure2") }}</b>
        <img src="/img/help/iphone/iphone-accelerometer-2.jpg"/>
      </li>
    </ol>

    <div class="go-back-to-adventures">
      {{ $t("help.onceDone") }}<br/><br/>

      <button type="button" class="button is-success" @click="move_back_to_ticket()">
        {{ $t("help.backToAdventure") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpIphoneAccelerometer',
  methods: {
    move_back_to_ticket () {
      this.$router.push({
        name: 'Ticket',
        params: { ticketUid: this.$route.params.ticketUid }
      })
    }
  }
}
</script>

<style lang="sass">
</style>
