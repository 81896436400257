<template>
  <div class="layout-wrapper">
    <div class="columns first-row is-mobile" :style="firstRowStyle">
      <div class="column is-12">
        <div :style="firstColumnStyle" class="column-wrapper">
          <Zone name="top"></Zone>
        </div>
      </div>
    </div>
    <div class="columns second-row is-mobile scrollable-zone" :style="secondWrapperStyle">
      <div class="column is-12">
        <div :style="secondColumnStyle" class="column-wrapper">
          <Zone name="main"></Zone>
        </div>
      </div>
    </div>
    <div class="columns third-row is-mobile" :style="thirdRowStyle">
      <div class="column is-12">
        <div :style="thirdColumnStyle" class="column-wrapper">
          <Zone name="bottom"></Zone>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Zone from '@/components/Zone'

export default {
  name: 'LayoutFixedtopbottom',
  components: { Zone },
  computed: {
    topHeight () {
      let pixels = this.$store.state.layout.attributes.heightTopPixels
      if (!pixels) {
        pixels = 100
      }
      return pixels.toString() + 'px'
    },
    bottomHeight () {
      let pixels = this.$store.state.layout.attributes.heightBottomPixels
      if (!pixels) {
        pixels = 100
      }
      return pixels.toString() + 'px'
    },
    firstRowStyle () {
      const res = {}
      res.height = this.topHeight
      return res
    },
    firstColumnStyle () {
      const res = {}
      const paddingtop = this.$store.state.layout.attributes.paddingtop
      if (paddingtop === undefined) {
        res['padding-top'] = '80px'
      } else {
        res['padding-top'] = paddingtop
      }
      const paddingleft = this.$store.state.layout.attributes.paddingleft
      if (paddingleft !== undefined) {
        res['padding-left'] = paddingleft
      }
      const paddingright = this.$store.state.layout.attributes.paddingright
      if (paddingright !== undefined) {
        res['padding-right'] = paddingright
      }
      console.log('')
      return res
    },
    secondWrapperStyle () {
      const res = {}
      res.top = this.topHeight
      res.bottom = this.bottomHeight
      return res
    },
    secondColumnStyle () {
      const res = {}
      const paddingleft = this.$store.state.layout.attributes.paddingleft
      if (paddingleft !== undefined) {
        res['padding-left'] = paddingleft
      }
      const paddingright = this.$store.state.layout.attributes.paddingright
      if (paddingright !== undefined) {
        res['padding-right'] = paddingright
      }
      return res
    },
    thirdColumnStyle () {
      const res = {}
      const paddingbottom = this.$store.state.layout.attributes.paddingbottom
      if (paddingbottom === undefined) {
        res['padding-bottom'] = '80px'
      } else {
        res['padding-bottom'] = paddingbottom
      }
      const paddingbottomtop = this.$store.state.layout.attributes.paddingbottomtop
      if (paddingbottomtop === undefined) {
        res['padding-top'] = '10px'
      } else {
        res['padding-top'] = paddingbottomtop
      }
      const paddingleft = this.$store.state.layout.attributes.paddingleft
      if (paddingleft !== undefined) {
        res['padding-left'] = paddingleft
      }
      const paddingright = this.$store.state.layout.attributes.paddingright
      if (paddingright !== undefined) {
        res['padding-right'] = paddingright
      }
      return res
    },
    thirdRowStyle () {
      const res = {}
      res.height = this.bottomHeight
      return res
    }
  }
}
</script>

<style scoped lang="sass">
  .layout-main-top-wrapper
    position: fixed
    width: 100%
    height: 100%

  .first-row
    position: fixed
    top: 0

  .second-row
    position: fixed
    left: 0
    right: 0
    overflow: auto
    margin-bottom: 0px !important

  .third-row
    position: fixed
    bottom: 0

  .column
    height: 100%
    padding: 0

  .column-wrapper
    height: 100%
    box-sizing: border-box

  .columns
    margin: 0px
</style>
