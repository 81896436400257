<template>
  <div>
    <div class="wrapper">
      <div class="container scrollable-zone">
        <Zone name="main"></Zone>
      </div>
    </div>
  </div>
</template>

<script>
import Zone from '@/components/Zone'

export default {
  name: 'LayoutCentered',
  components: { Zone }
}
</script>

<style scoped lang="sass">
  .container
    position: absolute
    top: 50%
    left: 50%
    width: 100%
    -moz-transform: translateX(-50%) translateY(-50%)
    -webkit-transform: translateX(-50%) translateY(-50%)
    transform: translateX(-50%) translateY(-50%)

  .wrapper
    position: fixed
    width: 100%
    height: 100%
</style>
