export default {
  state: {
    rankings: []
  },
  getters: {
    isGathering (state) {
      return state.rankings.length > 0
    }
  },
  actions: {
    // Gathering : dynamic rankings update
    socket_updateDynamicRankings (context, data) {
      context.commit('SET_GATHERING_RANKINGS', data)
    }
  },
  mutations: {
    SET_GATHERING_RANKINGS (state, value) {
      state.rankings = value
    }
  }
}
