<script>
import screenfull from 'screenfull'

export default {
  props: ['attributes', 'number'],
  data () {
    return {
      validated: false,
      selectedValue: null
    }
  },
  methods: {
    async select (choice) {
      if (this.validated) {
        return
      }
      if (this.attributes.requestFullScreen && screenfull.isEnabled) {
        screenfull.request()
      }
      this.validated = true
      this.selectedValue = choice.key
      await this.$store.dispatch('storeData', {
        key: this.attributes.key,
        value: choice.key
      })
      if (this.attributes.keyText) {
        await this.$store.dispatch('storeData', {
          key: this.attributes.keyText,
          value: choice.text
        })
      }
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    }
  }
}
</script>
