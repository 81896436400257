<template>
  <div>
    <div class="horizontal-images" ref="container">
      <div id="dashed-line"></div>
      <i class="fas fa-caret-down"></i>
      <i v-if="showIndicators && isValidTop">
        <i class="fas fa-check indicator-top"></i>
      </i>
      <i v-if="showIndicators && !isValidTop">
        <i class="fas fa-times indicator-top"></i>
      </i>
      <i v-if="showIndicators && isValidBottom">
        <i class="fas fa-check indicator-bottom"></i>
      </i>
      <i v-if="showIndicators && !isValidBottom">
        <i class="fas fa-times indicator-bottom"></i>
      </i>
      <div
        class="image"
        v-for="(image, index) in attributes.images"
        :key="index"
        :style="styles[index]"
        @mousedown="onMouseDown(index, $event)"
        @touchstart="onMouseDown(index, $event)"
        @mousemove="onMouseMove(index, $event)"
        @touchmove="onMouseMove(index, $event)"
        @mouseup="onMouseLeave(index)"
        @mouseleave="onMouseLeave(index)"
        @touchend="onMouseLeave(index)"
      >
        <img :src="image.url" @load="onImageLoad(index, $event)"/>
      </div>
    </div>
    <div
      class="button is-default"
      v-if="isValid"
      @click="validate"
      :ref="onDisplaySuccessButton">
      {{ $t("common.congrats") }}&nbsp;<i class="fa fa-angle-right"></i>
    </div>
  </div>
</template>

<script>
import PuzzleMixin from '@/mixins/Puzzle'

export default {
  name: 'SceneHorizontalImagesToAlign',
  mixins: [PuzzleMixin],
  props: ['attributes', 'number'],
  data () {
    return {
      styles: new Array(this.attributes.images.length).fill({}),
      slidingIndex: null,
      slidingStart: null,
      isValid: false,
      isValidTop: false,
      isValidBottom: false,
      imagesSlide: [],
      imagesWidth: new Array(this.attributes.images.length).fill([])
    }
  },
  created () {
    for (const image of this.attributes.images) {
      this.imagesSlide.push(image.startSlide)
    }
    for (let i = 0; i < this.styles.length; i++) {
      this.styles[i].height = this.attributes.images[i].height.toString() + 'px'
    }
  },
  computed: {
    positions () {
      const middleScreenX = Math.round(this.$refs.container.offsetWidth / 2)
      const res = []

      for (let i = 0; i < this.imagesSlide.length; i++) {
        let target = middleScreenX % this.imagesWidth[i]
        target -= this.imagesSlide[i]

        while (target > this.imagesWidth[i]) {
          target -= this.imagesWidth[i]
        }
        while (target < 0) {
          target += this.imagesWidth[i]
        }
        res.push(target)
      }
      return res
    },
    // Should we display helper indicators
    showIndicators () {
      if (this.attributes.enableHelperAtClue === -1) {
        // Not defined
        return false
      }
      // Display indicators once cumulative penalties for displaying
      // clues on current scene is bigger than the given attribute
      return this.$store.state.lastDisplayedClue >= this.attributes.enableHelperAtClue
    }
  },
  methods: {
    onImageLoad (index, evt) {
      /* eslint-disable object-property-newline */
      this.styles[index] = {
        background: 'url(\'' + this.attributes.images[index].url + '\') repeat', 'background-size': 'auto ' + this.attributes.images[index].height.toString() + 'px',
        'background-position': this.imagesSlide[index] + 'px',
        height: this.attributes.images[index].height.toString() + 'px'
      }
      this.imagesWidth[index] = evt.target.width
    },
    onMouseDown (index, evt) {
      let screenX = evt.screenX
      if (!screenX) {
        screenX = evt.touches[0].screenX
      }
      this.slidingIndex = index
      this.slidingStart = screenX
    },
    onMouseMove (index, evt) {
      let screenX = evt.screenX
      if (!screenX) {
        screenX = evt.touches[0].screenX
      }
      if (this.slidingIndex === index) {
        const move = screenX - this.slidingStart
        this.imagesSlide[index] += move
        this.slidingStart = screenX

        this.styles[index]['background-position'] = this.imagesSlide[index].toString() + 'px'
      }
    },
    onMouseLeave (index, evt) {
      this.slidingIndex = null
      this.slidingStart = null
      this.checkValidity()
    },
    // Check if positions are correct
    checkValidity () {
      if (this.isValid) {
        return
      }
      this.isValid = true
      for (let i = 0; i < this.attributes.images.length; i++) {
        const image = this.attributes.images[i]
        const isLayerValid = Math.abs(this.positions[i] - image.correctX) <= 15
        if (!isLayerValid) {
          this.isValid = false
        }
        if (i === 0) {
          this.isValidTop = isLayerValid
        }
        if (i === this.attributes.images.length - 1) {
          this.isValidBottom = isLayerValid
        }
      }
      console.log('top', this.isValidTop, 'bottom', this.isValidBottom)
      if (this.isValid) {
        this.$nextTick(this.playVictorySound)
      }
    },
    onDisplaySuccessButton (el) {
      const comp = this
      setTimeout(function () {
        if (el && comp.isValid) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    },
    validate () {
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    }
  }
}
</script>

<style scoped lang="sass">
  .image
    img
      display: none
    width: 100%

  .horizontal-images
    text-align: center
    font-size: 80px
    line-height: 80px
    position: relative
    padding-bottom: 15px

  #dashed-line
    position: absolute
    z-index: 3006
    top: 60px
    left: 50%
    margin-left: -2px
    width: 3px
    bottom: 15px
    background: url('@/assets/dashed.png') repeat

  .fa-xmark, .fa-check
    position: absolute
    font-size: 18px
    font-weight: bold
    left: 50%
    margin-left: -7px
    z-index: 3007

  .fa-xmark
    color: #bf2323

  .fa-check
    color: green

  .indicator-top
    top: 70px

  .indicator-bottom
    bottom: 0px

  .button.is-default
    margin-top: 20px
    margin-bottom: 30px
</style>
