<template>
  <div>
    <img :src="attributes.url" ref="theImg" @click="onClick"/>
  </div>
</template>

<script>
import PuzzleMixin from '@/mixins/Puzzle'
import isSafari from '@/functions/safari'

export default {
  name: 'SceneImageWithSoundsToReproduce',
  mixins: [PuzzleMixin],
  props: ['attributes', 'number'],
  data () {
    return {
      suite: [],
      imageRealWidth: 1,
      imageRealHeight: 1,
      cachedSounds: {}
    }
  },
  async created () {
    for (const sound of this.attributes.sounds) {
      const res = await fetch(sound.url)
      const data = await res.arrayBuffer()
      this.cachedSounds[sound.key] = 'data:audio/mpeg;base64,' + btoa(String.fromCharCode(...new Uint8Array(data)))
    }
  },
  mounted () {
    const img = new Image()
    const component = this
    img.onload = function () {
      component.imageRealWidth = this.width
      component.imageRealHeight = this.height
    }
    img.src = this.attributes.url
  },
  methods: {
    onClick (evt) {
      const ratioX = this.$refs.theImg.width / this.imageRealWidth
      const ratioY = this.$refs.theImg.height / this.imageRealHeight

      const correctedX = evt.offsetX / ratioX
      const correctedY = evt.offsetY / ratioY

      const audioTrack = this.$store.state.audio.tracks[2]
      audioTrack.loop = false

      for (const sound of this.attributes.sounds) {
        if (correctedX >= sound.x[0] &&
            correctedX <= sound.x[1] &&
            correctedY >= sound.y[0] &&
            correctedY <= sound.y[1]) {
          audioTrack.volume = 1
          if (isSafari()) {
            audioTrack.src = this.cachedSounds[sound.key]
          } else {
            audioTrack.src = sound.url
          }
          audioTrack.currentTime = 0
          audioTrack.play()
          this.addToSuite(sound.key)
          return
        }
      }
      this.resetSuite()
    },
    resetSuite () {
      this.suite = []
    },
    async addToSuite (key) {
      this.suite.push(key)
      const index = this.suite.length - 1
      if (this.attributes.solution[index] !== key) {
        // Wrong ! Reset suite
        this.resetSuite()
        return
      }
      if (this.attributes.solution.length === this.suite.length) {
        // YAY !
        await new Promise(resolve => setTimeout(resolve, 500))
        this.playVictorySound()
        this.$store.dispatch('validateItem', {
          number: this.number
        })
      }
    }
  }
}
</script>

<style scoped lang="sass">
  img
    width: 100%
</style>
