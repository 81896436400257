<template>
  <div id="select-language-entry">
    <div>
      <div class="background" :style="backgroundStyle"></div>
      <div id="select-language-content">
        <LanguageChoice
          :ticketUid="ticketUid"
          @languageSelected="onLanguageSelected"
        ></LanguageChoice>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/api'
import LanguageChoice from '@/components/LanguageChoice'

export default {
  name: 'SelectLanguage',
  components: { LanguageChoice },
  computed: {
    ticketUid () {
      return this.$route.params.ticketUid
    },
    backgroundStyle () {
      return {
        'background-image': 'url("' + this.$store.state.mainInfos.defaultBackground + '")'
      }
    }
  },
  methods: {
    async onLanguageSelected (value) {
      console.log('onLanguageSelected', value)
      await axiosInstance.post('/set-language/' + this.ticketUid, {
        value
      })
      this.$store.commit('SET_LANGUAGE', value)
      this.$router.push({
        name: 'VisitorEntry',
        query: {
          redirectTo: this.$route.query.redirectTo
        }
      })
    },
    onInitError (err) {
      console.log('INIT ERROR')
      if (err.response.status === 403) {
        // Ticket is not valid anymore
        this.$router.push({ name: 'TicketIsConsumed' })
      } else if (err.response.status === 404) {
        // Ticket does not exist
        this.$router.push({ name: 'TicketDoesNotExist' })
      } else {
        console.log('Unexpected init error ', err)
      }
    }
  }
}
</script>

<style lang="sass" scoped>

  .background
    position: fixed
    top: 0
    left: 0

    background-size: 100% auto
    @media (max-aspect-ratio: 1/1)
      background-size: auto 100%

    background-position: bottom center
    background-repeat: no-repeat

    width: 100%
    height: 100%
    z-index: 2

  #select-language-content
    z-index: 100
    padding-top: 200px
    position: relative

</style>
