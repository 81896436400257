<template>
  <div>
    <div v-if="gpsDisabled" id="disabled-gps-message">
      {{ $t("gps.disabled1") }}<br/>
      {{ $t("gps.disabled2") }}<br/><br/>

      <button class="button is-success" @click="startGps">
        {{ $t("gps.disabledLetsGo") }}
      </button>
    </div>
    <div class="wait-for-gps" v-if="!distanceToTarget">
      <progress class="progress is-small is-default" max="100"></progress>
    </div>
    <div class="distanceToTarget" v-if="distanceToTarget">
      {{ distanceToTarget }}m
    </div>
  </div>
</template>

<script>
import GpsSceneWithTargetMixin from '@/mixins/GpsSceneWithTarget'
export default {
  name: 'SceneLocateByDistance',
  mixins: [GpsSceneWithTargetMixin],
  props: ['attributes', 'number'],
  data () {
    return {
      isValidated: false
    }
  },
  computed: {
    // Target, used by mixin to calculate distance
    target () {
      return this.attributes.target
    }
  },
  methods: {
    validate () {
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    },
    // Raised by mixin when user is close to target
    onDistanceUpdate (value) {
      if (this.isValidated) {
        return
      }
      console.log('distance updated', value)
      if (value < 20) {
        this.validate()
        this.isValidated = true
      }
    }
  }
}
</script>

<style scoped lang="sass">
  .distanceToTarget
    font-weight: bold
    font-size: 80px
    margin: auto

  .wait-for-gps, .distanceToTarget
    padding-top: 20px

  .progress
    width: 200px
    margin: auto
</style>
