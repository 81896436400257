import Zone from './zone'

// The layout is the way the scene is currently rendered
class Layout {
  constructor () {
    this.key = 'fullscreen'
    this.attributes = {}
    this.background = null
    this.backgroundAnchor = 'top center'
    this.zones = {}
  }

  getZone (key) {
    if (!this.zones[key]) {
      this.zones[key] = new Zone()
    }
    return this.zones[key]
  }

  clearZones () {
    this.zones = {}
  }

  clearZone (key) {
    delete this.zones[key]
  }
}

export default Layout
