<script>
import ImageToTapMixin from '@/mixins/ImageToTap'

export default {
  name: 'SceneImageWithMultipleTaps',
  mixins: [ImageToTapMixin],
  methods: {
    onClick (evt) {
      const targets = this.attributes.targets
      let success = false
      for (const i in targets) {
        const target = targets[i]
        const targetDistance = Math.sqrt((evt.offsetX - target.x) ** 2 + (evt.offsetY - target.y) ** 2)
        if (targetDistance < 20) {
          // Success
          this.playVictorySound()
          success = true
          this.foundTargets.push(target)
        }
      }
      if (!success) {
        // Failure
        this.wrongAnswer = true
        const component = this
        setTimeout(function () {
          component.wrongAnswer = false
        }, 3000)
        this.playFailedSound()
      }
      if (this.foundTargets.length === targets.length) {
        this.$store.dispatch('validateItem', {
          number: this.number
        })
      }
    }
  }
}
</script>
