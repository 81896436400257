<template>
  <div class="hunting-dashboard">
    <div class="hunting-text">
      {{ huntingData.text }}
    </div>
    <div class="reward-text">
      {{ huntingData.subtext }}
    </div>

    <div
      v-for="huntingItem in displayedData"
      :key="huntingItem.key"
      class="columns is-mobile hunting-item is-vcentered">
      <div class="column is-2">
        <img :src="huntingItem.icon" class="hunting-item-img"/>
      </div>
      <div class="column is-6 hunting-item-text">
        {{ huntingItem.name }}
      </div>
      <div class="column is-2 hunting-item-count">
        {{ Math.min(huntingItem.target, huntingItem.count) }} / {{ huntingItem.target }}
      </div>
    </div>

    <button class="button hunting-button is-info" @click="goToMap()">
      {{ $t("hunting.showOnMap") }}
    </button>

  </div>
</template>

<script>
export default {
  name: 'HuntingDashboard',
  props: ['huntingKey'],
  computed: {
    huntingData () {
      return this.$store.state.huntings[this.huntingKey]
    },
    huntingFoundItems () {
      return this.$store.state.huntings_found_items
    },
    displayedData () {
      const res = []
      for (const huntingItem of this.huntingData.items) {
        let count = this.huntingFoundItems[huntingItem.key]
        if (!count) {
          count = 0
        }
        res.push(
          {
            key: huntingItem.key,
            icon: huntingItem.icon,
            name: huntingItem.name,
            target: huntingItem.target,
            count
          }
        )
      }
      return res
    }
  },
  methods: {
    goToMap () {
      this.$emit('go-to-map', { go: true })
    }
  }
}
</script>

<style scoped lang="sass">
  .hunting-dashboard
    padding-top: 100px

  .columns
    max-width: 90%
    margin: auto

  .hunting-text
    font-weight: bold
    margin-bottom: 10px
    text-align: center

  .reward-text
    text-align: center
    margin-bottom: 30px
    padding: 0px 20px

  .hunting-item-img
    width: 50px

  .hunting-button
    margin-top: 50px

</style>
