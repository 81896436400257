<template>
  <div id="phone-call-wrapper">
    <figure class="image is-128x128 who-is-calling-pic">
     <img class="is-rounded" :src="attributes.who.pictureUrl">
    </figure>
    <div class="who-is-calling-name">
      {{ attributes.who.name }}
    </div>
    <div class="who-is-calling-phone-number">
      01 00 00 00 00
    </div>

    <div class="btns-wrapper">

      <div class="columns is-mobile" >
        <div class="column" v-if="!inProgress">
          <div class="call-btn call-refuse">
            <i class="icon-wrapper">
              <i class="fa fa-phone"></i>
            </i>
          </div>
        </div>
        <div class="column">
          <div
            :class="greenBtnClass"
            @click="acceptCall"
            @touchstart="acceptCall"
          >
            <i class="icon-wrapper">
              <i class="fa fa-phone"></i>
            </i>
          </div>
          <div
            class="skip-call-btn"
            v-if="inProgress"
            @click="validate"
            @touchstart="validate"
          >
            <button class="button">
              <span>{{ $t("common.skip") }}</span>
              <span class="icon is-small">
                <i class="fa fa-forward"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ScenePhoneCall',
  props: ['attributes', 'number'],
  data () {
    return {
      ringToneAudio: null,
      voiceAudio: null,
      inProgress: false,
      unmounted: false
    }
  },
  mounted () {
    // Play sound when mounted / displayed
    console.log('ScenePhoneCall')
    const audioTrack = this.getAudioTrack()
    audioTrack.volume = 1
    audioTrack.loop = true
    audioTrack.src = this.attributes.ringtone
    if (window.navigator.vibrate) {
      this.startVibration()
    }
  },
  unmounted () {
    const audioTrack = this.getAudioTrack()
    audioTrack.volume = 0
    audioTrack.pause()
    audioTrack.loop = false
    this.unmounted = true
  },
  computed: {
    greenBtnClass () {
      if (this.inProgress) {
        return 'call-btn call-in-progress'
      }
      return 'call-btn call-accept'
    }
  },
  methods: {
    getAudioTrack () {
      return this.$store.state.audio.tracks[0]
    },
    acceptCall () {
      if (this.inProgress) {
        return
      }
      this.inProgress = true
      const audioTrack = this.getAudioTrack()
      audioTrack.src = this.attributes.voice
      audioTrack.loop = false
      audioTrack.addEventListener('ended', this.onVoiceEnded)
    },
    onVoiceEnded () {
      this.validate()
    },
    validate () {
      const audioTrack = this.getAudioTrack()
      audioTrack.removeEventListener('ended', this.onVoiceEnded)
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    },
    startVibration () {
      if (this.inProgress || this.unmounted) {
        return
      }
      window.navigator.vibrate(1000)
      setTimeout(this.startVibration, 2000)
    }
  }
}
</script>

<style lang="sass">
  #phone-call-wrapper
    background-color: #8D7268
    height: 100%

  .who-is-calling-pic
    margin: auto
    padding-top: 80px
    img
      background-color: white

  .who-is-calling-name
    margin: auto
    color: white
    font-size: 36px
    padding-top: 40px

  .who-is-calling-phone-number
    margin: auto
    color: white
    padding-bottom: 100px

  .call-btn
    transition: all 2s
    width: 80px
    height: 80px
    position: relative
    margin: 0 auto
    border-radius: 100%
    border: none

    .icon-wrapper
      width: 80px
      height: 80px
      font-size: 40px
      line-height: 40px
      border-radius: 100%
      position: absolute
      color: #fff
      left: 0px
      top: 0px

  .call-accept
    background: #4CAF50

    .icon-wrapper
      transform: rotate(90deg) translate(0px, 18px)

  .call-accept
    cursor: pointer
    background: #4CAF50
    animation: play 2s ease infinite
    -webkit-backface-visibility: hidden
    -moz-backface-visibility: hidden
    -ms-backface-visibility: hidden
    backface-visibility: hidden

  .call-in-progress, .call-refuse
    background: #ff3a31

    .icon-wrapper
      transform: rotate(-135deg) translate(-1px, 17px)

  .skip-call-btn
    margin-top: 40px
    .button
      background: rgba(255, 255, 255, 0.8)
      border: none

  @keyframes play
    0%
        transform: scale(1)
    15%
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4)
    25%
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4), 0 0 0 20px rgba(255, 255, 255, 0.2)
    25%
        box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.4), 0 0 0 30px rgba(255, 255, 255, 0.2)

  .btns-wrapper
    *
      transition: all 3s

  @media (max-height: 550px)
    .who-is-calling-phone-number
      padding-bottom: 20px
    .skip-call-btn
      margin-top: 20px

</style>
