<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'SceneJumpDetector',
  props: ['attributes', 'number'],
  data () {
    return {
      cumulativeZ: 0,
      cumulativeY: 0,
      isValidated: false
    }
  },
  mounted () {
    window.addEventListener('devicemotion', this.onDeviceMotion)
  },
  unmounted () {
    window.removeEventListener('devicemotion', this.onDeviceMotion)
  },
  methods: {
    onDeviceMotion (evt) {
      if (this.isValidated) {
        return
      }
      const current = evt.acceleration
      const treshold = 20
      if (current.z > treshold) {
        this.cumulativeZ += current.z
      }
      if (current.y > treshold) {
        this.cumulativeY += current.y
      }
      if (this.cumulativeZ > 500 || this.cumulativeY > 500) {
        this.validate()
        this.isValidated = true
      }
    },
    validate () {
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    }
  }
}
</script>

<style scoped lang="sass">
</style>
