<template>
  <div class="modal" :class="{'is-active': displayed}" id="evidences-modal">
    <div class="modal-background"></div>
    <div class="modal-content" v-if="evidences && displayed">
      <EvidencesContent :withImages="true"></EvidencesContent>
    </div>

    <button
      class="modal-close is-large"
      aria-label="close"
      @click="closeModal">
    </button>
  </div>
</template>

<script>
import EvidencesContent from '@/components/evidences/EvidencesContent'

export default {
  name: 'EvidencesModal',
  components: { EvidencesContent },
  computed: {
    evidences () {
      return this.$store.state.evidences
    }
  },
  props: ['displayed'],
  methods: {
    closeModal () {
      this.$emit('update:displayed', false)
    }
  }
}
</script>

<style scoped lang="sass">
  .modal
    z-index: 110

  .modal-content
    height: 100%
    max-height: none
    padding: 50px
    padding-top: 100px

  .modal-background
    background-color: rgba(255, 255, 255, 0.95)

  .modal-close::before, .modal-close::after
    background-color: black

</style>
