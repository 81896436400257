<template>
  <div>
    <div id="dev-panel" v-if="isDevModeEnabled">
      <button
        class="button is-danger"
        :disabled="btnDisabled"
        @click="reset">
        <i class="fas fa-power-off"></i>
      </button>
      <button
        class="button"
        :disabled="btnDisabled"
        @click="goBack">
        <i class="fas fa-chevron-circle-left"></i>
      </button>
      <button class="button" @click="fullscreen"><i class="fas fa-expand"></i></button>
      <button
        class="button"
        :class="devModeBtnClass"
        @click="toggleDevAcceleration">
        <i class="fas fa-fighter-jet"></i>
      </button>
      <button
        class="button is-info"
        :disabled="btnDisabled"
        @click="validate">
        <i class="fas fa-forward"></i>
      </button>
    </div>
    <div :class="routerWrapperClasses">
      <router-view/>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'

export default {
  methods: {
    reset () {
      this.$store.dispatch('reset', { mode: 'start' })
    },
    // Go to the previous blocking item
    goBack () {
      this.$store.dispatch('reset', { mode: 'previous' })
    },
    toggleDevAcceleration (value) {
      this.$store.commit('TOGGLE_DEV_ACCELERATION', value)
    },
    validate () {
      this.$store.dispatch('validateItem', {
        number: this.$store.state.itemNumber
      })
    },
    fullscreen () {
      if (screenfull.isEnabled) {
        screenfull.request()
      }
    }
  },
  computed: {
    isDevModeEnabled () {
      return this.$store.state.devmode.enable
    },
    devmodeAccelerated () {
      return this.$store.state.devmode.accelerate
    },
    devModeBtnClass () {
      if (this.devmodeAccelerated) {
        return 'is-success'
      }
      return 'is-default'
    },
    btnDisabled () {
      return this.$store.state.updating
    },
    routerWrapperClasses () {
      if (this.$store.state.forceIosScreenOrientation === 'portrait') {
        return 'force-ios-portrait-orientation'
      }
      if (this.$store.state.forceIosScreenOrientation === 'landscape') {
        return 'force-ios-landscape-orientation'
      }
      return ''
    }
  }
}
</script>

<style lang="sass">
#app
  text-align: center
  color: #2c3e50
  height: 100%

#app, button
  font-family: "Open Sans", Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

#dev-panel
  position: fixed
  top: 1vh
  left: 1vh
  z-index: 100000

  button
    padding: 5px 10px
    margin-right: 3px

div.button,button.button, a.button
  &.is-info
    background-color: #92c1be !important

button.button
  white-space: normal
  max-width: 90%
  height: auto

button.selected
  position: relative
  &.button[disabled]
    opacity: 1
  &:before
    content: ''
    position: absolute
    top: 0px
    left: 0px
    right: 0px
    bottom: 0px
    background-color: #ccc
    opacity: 0.3
    z-index: 1006
  background-color: #92c1be !important
  color: white
  border: solid 1px #608e99

.select select:focus, .textarea:focus, .input:focus, .select select.is-focused, .is-focused.textarea, .is-focused.input, .select select:active, .textarea:active, .input:active, .select select.is-active, .is-active.textarea, .is-active.input
  border-color: #CCC !important

body
  margin: 0

.force-ios-portrait-orientation
  @media screen and (orientation: landscape)
    transform: rotate(-90deg)
    transform-origin: left top
    width: 100vh
    height: 100vw
    overflow-x: hidden
    position: absolute
    top: 100%
    left: 0

.force-ios-landscape-orientation
  @media screen and (orientation: portrait)
    transform: rotate(-90deg)
    transform-origin: left top
    width: 100vh
    height: 100vw
    overflow-x: hidden
    position: absolute
    top: 100%
    left: 0

// Trying to disable a bulma CSS affecting columns background
.columns:not(:last-child)
  margin-bottom: 0px !important

body,.home,.ticket,.zone
  overscroll-behavior: none // Prevent Android to refresh page when scrolling top (pull down to refresh)

body
  touch-action: pan-x pan-y pinch-zoom // Prevent iOS to zoom by touch action

</style>
