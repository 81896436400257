<template>
  <div>
    <EvidencesContent></EvidencesContent>
  </div>
</template>

<script>
import EvidencesContent from '@/components/evidences/EvidencesContent'

export default {
  name: 'SceneDisplayEvidencesInline',
  props: ['attributes', 'number'],
  components: { EvidencesContent }
}
</script>

<style lang="sass" scoped>
</style>
