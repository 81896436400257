<script>
import axiosInstance from '@/api'

export default {
  name: 'ReportErrorMixin',
  data () {
    return {
      reportText: '',
      reportSent: false
    }
  },
  methods: {
    async reportError (text) {
      this.reportSent = true
      console.log('report error', text)
      await axiosInstance.post('/send-anomaly-feedback/' + this.ticketUid, {
        text: this.reportText
      })
    }
  }
}
</script>
