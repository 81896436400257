<template>
  <div>
    <RankingsContent :showImages="true" :showRanks="true"></RankingsContent>
  </div>
</template>

<script>
import RankingsContent from '@/components/rankings/RankingsContent'

export default {
  name: 'SceneDynamicRankings',
  props: ['attributes', 'number'],
  components: { RankingsContent }
}
</script>

<style lang="sass" scoped>
</style>
