<template>
  <div>
    <div class="raw-header">
      {{ attributes.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SceneRawHeader',
  props: ['attributes']
}
</script>

<style lang="sass" scoped>
  .raw-header
    font-size: 18px
    font-weight: bold
    text-align: left
    padding-bottom: 20px
    padding-left: 40px
</style>
