import { createStore } from 'vuex'
import Layout from './layout'
import AudioStore from './audio'
import GatheringStore from './gathering'
import router from '@/router'

import axiosInstance from '@/api'

function defaultState () {
  return {
    ticketUid: null,
    gotPermissions: false,
    mainInfos: {}, // Main ticket infos
    itemNumber: -1,
    lastItemNumber: 100,
    lastValidate: null,
    year: {
      value: null,
      shake: false
    },
    energy: {
      value: null,
      shake: false
    },
    sceneItems: [],
    evidences: [],
    displayEvidences: true, // By default, display evidences if any
    displayHuntings: true, // By default, display huntings if any
    huntings: {},
    huntings_found_items: {},
    layout: new Layout(),
    clues: [],
    firstClues: true,
    lastDisplayedClue: 0,
    devmode: {
      enable: false,
      accelerate: true
    },
    blurDisplay: false,
    baseTimeElapsed: 0, // Time elapsed _before_ current scene
    timerStartedAt: null,
    forceIosScreenOrientation: false,
    updating: false, // Locked while updating
    sceneItemsInProgress: [] // Scene items being displayed (with automatic timer)
  }
}

export default createStore({
  modules: {
    audio: AudioStore,
    gathering: GatheringStore
  },
  state: defaultState(),
  actions: {
    // Switch to a given ticket
    switchToTicket (context, ticketUid) {
      console.log('switchtoTicket', ticketUid)
      // Store ticket uid
      context.commit('SET_TICKET_UID', ticketUid)
      // Tell backend that we are ready to go !
      if (this.$app.$socket.connected) {
        console.log('---> switch ticket init')
        this.$app.$socket.client.emit('ticket_init', {
          ticket_uid: ticketUid
        })
      }
    },
    async reset (context, data) {
      if (context.state.updating) { return }
      context.state.updating = true
      context.commit('RESET_SCENE')
      // Keep ticket uid and devmode
      const ticketUid = context.state.ticketUid
      const devmode = context.state.devmode
      const gotPermissions = context.state.gotPermissions
      const stateResetData = defaultState()
      Object.assign(stateResetData, { devmode })
      Object.assign(context.state, stateResetData)
      context.state.ticketUid = ticketUid
      context.state.gotPermissions = gotPermissions
      this.$app.$socket.client.emit('reset', {
        ticket_uid: ticketUid,
        mode: data.mode
      })
    },
    socket_connected (context, data) {
      // Connected to socket.io server
      // Can be the first time, but can also be because of reconnection
      // In this case, ensure that we are properly listening to the ticket uid room
      console.log('CONNECTED')
      if (context.state.ticketUid) {
        this.$app.$socket.client.emit('ensure_listening_ticket', {
          ticket_uid: context.state.ticketUid
        })
        console.log('---> connected ticket init')
        // Retrieve ticket data from beginning in case we missed something
        this.$app.$socket.client.emit('ticket_init', {
          ticket_uid: context.state.ticketUid,
          current_item_number: context.state.itemNumber
        })
      }
    },
    socket_enableDeveloperMode (context, data) {
      context.commit('ENABLE_DEVELOPER_MODE')
    },
    // Reset done on server side
    socket_hasBeenReset (context, data) {
      console.log('has been reset !')
      context.dispatch('switchToTicket', context.state.ticketUid)
    },
    socket_ticketDoesNotExist (context, data) {
      router.push({ name: 'TicketDoesNotExist' })
    },
    socket_ticketIsConsumed (context, data) {
      router.push({ name: 'TicketIsConsumed' })
    },
    // Clue will be displayed
    socket_displayClue (context, data) {
      context.commit('SET_LAST_DISPLAYED_CLUE', data.number)
    },
    // Time elapsed has been updated
    socket_timeElapsedUpdate (context, data) {
      context.commit('SET_TIME_ELAPSED', data.time_elapsed)
    },
    // Hunting status has been updated
    socket_huntingStatusUpdate (context, data) {
      context.commit('UPDATE_HUNTING_STATUS', data)
    },
    // Receiving a scene update from socket
    socket_sceneUpdate (context, data) {
      context.state.updating = true
      console.log('updating')
      if (data.reset) {
        context.commit('RESET_SCENE')
      }
      context.commit('SET_LAST_ITEM_NUMBER', data.last_item_number)
      context.commit('SET_TIMER_STARTED_AT', data.timer_started_at)
      context.commit('SET_TIME_ELAPSED', data.time_elapsed)
      context.commit('SET_LAST_DISPLAYED_CLUE', 0)
      context.commit('SET_SCENE_ITEMS_IN_PROGRESS', data.items)
      context.dispatch('loadScene')
    },
    // Function called to "read" scene items, with automatic timers
    loadScene (context, args) {
      if (args === undefined) {
        args = {}
      }
      if (context.state.sceneItemsInProgress.length === 0) {
        context.state.updating = false
        return null
      }
      if (args.expectedRemainingScenes && args.expectedRemainingScenes > context.state.sceneItemsInProgress.length) {
        // Scene was forced manually to pass
        return null
      }
      if (args.lastValidate && context.state.lastValidate !== null && args.lastValidate !== context.state.lastValidate) {
        // This argument is only passed in automated loads (timed after a delay)
        // Some scenes were forced manually to pass
        // Then a validation occured, but some old "forced loads" happened after
        // They are cancelled
        return null
      }
      const item = context.state.sceneItemsInProgress[0]
      context.commit('POP_SCENE_ITEM_IN_PROGRESS')

      if (item.number <= context.state.itemNumber) {
        // Security : prevent duplicated items
        console.error('Received duplicated items', item, context.state.itemNumber)
        console.log('Skipping...')
      } else {
        context.commit('SCENE_ITEM_' + item.type, {
          number: item.number,
          data: item.attributes
        })
        context.commit('PUSH_SCENE_ITEM', item)
        context.commit('SET_ITEM_NUMBER', item.number)
      }

      function loadNextScene (argsToPass) {
        context.dispatch('loadScene', argsToPass)
      }

      const nbRemainingItems = context.state.sceneItemsInProgress.length

      if (item.delayAfter) {
        let delay = item.delayAfter * 1000
        if (context.state.devmode.enable && context.state.devmode.accelerate) {
          delay = 50
        }
        setTimeout(loadNextScene, delay, {
          expectedRemainingScenes: nbRemainingItems,
          lastValidate: context.state.lastValidate
        })
      } else {
        loadNextScene({
          expectedRemainingScenes: nbRemainingItems
        })
      }
    },
    async sendPicture (context, payload) {
      await axiosInstance.post('/add-picture/' + context.state.ticketUid, {
        data: payload.data,
        name: payload.name
      })
    },
    // Store journey data
    async storeData (context, data) {
      await axiosInstance.post('/store-data/' + context.state.ticketUid, {
        key: data.key,
        value: data.value
      })
    },
    // Validate journey item
    validateItem (context, data) {
      if (context.state.updating) { return }
      // Clear clues at each validation
      context.commit('CLEAR_CLUES')
      console.log('validate item ', data.number)
      context.commit('SET_LAST_VALIDATE', Date.now())
      this.$app.$socket.client.emit('validate', {
        ticket_uid: context.state.ticketUid,
        item_number: data.number
      })
    },
    // Inform backend that a clue was showed
    async askForClue (context, data) {
      this.$app.$socket.client.emit('askForClue', {
        ticket_uid: context.state.ticketUid,
        scene_number: context.state.itemNumber,
        last_displayed: context.state.lastDisplayedClue
      })
    },
    // Inform backend to add a time penalty
    async addTimePenalty (context, data) {
      this.$app.$socket.client.emit('addTimePenalty', {
        ticket_uid: context.state.ticketUid,
        seconds: data.seconds
      })
    },
    async checkHuntCoordinates (context, data) {
      const res = await axiosInstance.post('/check-hunt-coords/' + context.state.ticketUid, {
        huntingKey: data.huntingKey,
        value: data.value
      })
      return res.data
    }
  },
  mutations: {
    CLEAR_CLUES (state) {
      if (state.clues.length > 0) {
        state.firstClues = false
      }
      state.clues = []
    },
    ENABLE_DEVELOPER_MODE (state) {
      state.devmode.enable = true
    },
    FORCE_IOS_SCREEN_ORIENTATION (state, value) {
      state.forceIosScreenOrientation = value
    },
    PUSH_SCENE_ITEM (state, item) {
      state.sceneItems.push(item)
    },
    RESET_SCENE (state) {
      state.itemNumber = -1
      state.sceneItems = []
      state.huntings = {}
      state.evidences = []
      state.layout = new Layout()
    },
    SET_LAST_ITEM_NUMBER (state, value) {
      state.lastItemNumber = value
    },
    SET_LAST_VALIDATE (state, value) {
      state.lastValidate = value
    },
    SET_TIMER_STARTED_AT (state, value) {
      if (!value) {
        state.timerStartedAt = null
      } else {
        state.timerStartedAt = new Date(value)
      }
    },
    SET_TIME_ELAPSED (state, value) {
      state.baseTimeElapsed = value
    },
    SET_LAST_DISPLAYED_CLUE (state, value) {
      state.lastDisplayedClue = value
    },
    SET_SCENE_ITEMS_IN_PROGRESS (state, value) {
      state.sceneItemsInProgress = value
    },
    POP_SCENE_ITEM_IN_PROGRESS (state) {
      if (state.sceneItemsInProgress.length === 0) {
        return
      }
      state.sceneItemsInProgress.shift()
      state.sceneItemsInProgress = JSON.parse(JSON.stringify(state.sceneItemsInProgress))
    },
    UPDATE_HUNTING_STATUS (state, value) {
      state.huntings_found_items = value.found_items
    },
    SET_PERMISSIONS_OK (state) {
      state.gotPermissions = true
    },
    SET_MAIN_INFOS (state, value) {
      state.mainInfos = value
    },
    SET_LANGUAGE (state, value) {
      state.mainInfos.language = value
    },
    SET_ITEM_NUMBER (state, value) {
      state.itemNumber = value
    },
    SET_TICKET_UID (state, value) {
      state.ticketUid = value
    },
    SCENE_ITEM_ActionButton (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('ActionButton', data, number)
    },
    SCENE_ITEM_AddEvidence (state, { number, data }) {
      state.evidences.push(data.text)
    },
    SCENE_ITEM_AddImage (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('Image', data, number)
    },
    SCENE_ITEM_AddImageToTap (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('ImageToTap', data, number)
    },
    SCENE_ITEM_AddImageWithMultipleTaps (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('ImageWithMultipleTaps', data, number)
    },
    SCENE_ITEM_AddImageWithSoundsToReproduce (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('ImageWithSoundsToReproduce', data, number)
    },
    SCENE_ITEM_AddYoutubeVideo (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('YoutubeVideo', data, number)
    },
    SCENE_ITEM_CharacterSpeaking (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('CharacterSpeaking', data, number)
    },
    SCENE_ITEM_ClearEvidences (state, { number, data }) {
      state.evidences = []
    },
    SCENE_ITEM_ClearZones (state, { number, data }) {
      state.clues = []
      if (data.all) {
        state.layout.clearZones()
      } else {
        for (const zone of data.zones) {
          state.layout.clearZone(zone)
        }
      }
    },
    SCENE_ITEM_Credits (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('Credits', data, number)
    },
    SCENE_ITEM_DisableBlur  (state, { number, data }) {
      state.blurDisplay = false
    },
    SCENE_ITEM_DisplayDynamicRankings (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('DynamicRankings', data, number)
    },
    SCENE_ITEM_DisplayEvidencesInline (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('DisplayEvidencesInline', data, number)
    },
    SCENE_ITEM_EnableBlur  (state, { number, data }) {
      state.blurDisplay = true
    },
    SCENE_ITEM_HideEvidencesButton (state, { number, data }) {
      state.displayEvidences = false
    },
    SCENE_ITEM_HideHuntings (state, { number, data }) {
      state.displayHuntings = false
    },
    SCENE_ITEM_HorizontalImagesToAlign (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('HorizontalImagesToAlign', data, number)
    },
    SCENE_ITEM_Hunting (state, { number, data }) {
      state.huntings[data.key] = data
    },
    SCENE_ITEM_ImageChoice (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('ImageChoice', data, number)
    },
    SCENE_ITEM_ImagesToLegend (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('ImagesToLegend', data, number)
    },
    SCENE_ITEM_InputsOnImage (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('InputsOnImage', data, number)
    },
    SCENE_ITEM_JumpDetector (state, { number, data }) {
      const zone = state.layout.getZone('main')
      zone.addComponent('JumpDetector', {}, number)
    },
    SCENE_ITEM_LargeTextInput (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('LargeTextInput', data, number)
    },
    SCENE_ITEM_LayeredPuzzle (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('LayeredPuzzle', data, number)
    },
    SCENE_ITEM_Locate (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('Locate', data, number)
    },
    SCENE_ITEM_LocateByDistance (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('LocateByDistance', data, number)
    },
    SCENE_ITEM_Map (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('Map', data, number)
    },
    SCENE_ITEM_MapNew (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('MapNew', data, number)
    },
    SCENE_ITEM_NoteBook (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('NoteBook', data, number)
    },
    SCENE_ITEM_NumberInput (state, { number, data }) {
      const zone = state.layout.getZone('main')
      zone.addComponent('NumberInput', data, number)
    },
    SCENE_ITEM_PhoneCall (state, { number, data }) {
      const zone = state.layout.getZone('main')
      zone.addComponent('PhoneCall', data, number)
    },
    SCENE_ITEM_PlaySound (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('PlaySound', data, number)
    },
    SCENE_ITEM_PullAnimation (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('PullAnimation', data, number)
    },
    SCENE_ITEM_QrCode (state, { number, data }) {
      const zone = state.layout.getZone('main')
      zone.addComponent('QrCode', data, number)
    },
    SCENE_ITEM_Rankings (state, { number, data }) {
      const zone = state.layout.getZone('main')
      zone.addComponent('Rankings', data, number)
    },
    SCENE_ITEM_RawHeader (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      zone.addComponent('RawHeader', data, number)
    },
    SCENE_ITEM_RawText (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      zone.addComponent('RawText', data, number)
    },
    SCENE_ITEM_RichTextWithBackground (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      zone.addComponent('RichTextWithBackground', data, number)
    },
    SCENE_ITEM_RotateAnimation (state, { number, data }) {
      const zone = state.layout.getZone('main')
      delete data.zone
      zone.addComponent('RotateAnimation', data, number)
    },
    SCENE_ITEM_SayHi (state, { number, data }) {
      const zone = state.layout.getZone('main')
      zone.addComponent('SayHi', data, number)
    },
    SCENE_ITEM_SayHiBasic (state, { number, data }) {
      const zone = state.layout.getZone('main')
      zone.addComponent('SayHiBasic', data, number)
    },
    SCENE_ITEM_ScoreDetails (state, { number, data }) {
      const zone = state.layout.getZone('main')
      zone.addComponent('ScoreDetails', data, number)
    },
    SCENE_ITEM_Selfie (state, { number, data }) {
      const zone = state.layout.getZone('main')
      delete data.zone
      zone.addComponent('Selfie', data, number)
    },
    SCENE_ITEM_SetBackground (state, { number, data }) {
      if (data.zone === 'overall') {
        state.layout.background = data.url
        state.layout.backgroundAnchor = data.anchor
      } else {
        const zone = state.layout.getZone(data.zone)
        zone.background = data.url
        zone.backgroundAnchor = data.anchor
      }
    },
    SCENE_ITEM_ShakingDetector (state, { number, data }) {
      const zone = state.layout.getZone('main')
      zone.addComponent('ShakingDetector', data, number)
    },
    SCENE_ITEM_ShowEvidencesButton (state, { number, data }) {
      state.displayEvidences = true
    },
    SCENE_ITEM_SetClues (state, { number, data }) {
      state.clues = []
      for (const clueData of data.clues) {
        state.clues.push(clueData)
      }
    },
    SCENE_ITEM_SetEnergy (state, { number, data }) {
      state.energy.value = data.value
      state.energy.shake = data.shake
    },
    SCENE_ITEM_SetYear (state, { number, data }) {
      state.year.value = data.value
      state.year.shake = data.shake
    },
    SCENE_ITEM_ShowHuntings (state, { number, data }) {
      state.displayHuntings = true
    },
    SCENE_ITEM_StopHunting (state, { number, data }) {
      delete state.huntings[data.key]
    },
    SCENE_ITEM_StopSound (state, { number, data }) {
      state.audio.tracks[data.track].volume = 0
      state.audio.tracks[data.track].pause()
    },
    SCENE_ITEM_SwitchLayout (state, { number, data }) {
      state.layout.key = data.name
      state.layout.attributes = data.attributes
    },
    SCENE_ITEM_TextInput (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('TextInput', data, number)
    },
    SCENE_ITEM_TimeBonus (state, { number, data }) {
      console.log('time bonus')
    },
    SCENE_ITEM_TimePenalty (state, { number, data }) {
      console.log('time penalty')
    },
    SCENE_ITEM_UniqueChoice (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('UniqueChoice', data, number)
    },
    SCENE_ITEM_UniqueChoiceImage (state, { number, data }) {
      const zone = state.layout.getZone(data.zone)
      delete data.zone
      zone.addComponent('UniqueChoiceImage', data, number)
    },
    TOGGLE_DEV_ACCELERATION (state) {
      state.devmode.accelerate = !state.devmode.accelerate
    }
  }
})
