<template>
  <div>
    <p class="control">
      <textarea
        v-model="textValue"
        class="textarea"
        :placeholder="attributes.placeholder"
        rows="6">
      </textarea>
    </p>
    <p class="control">
      <a class="button is-default" @click="validate">
        {{ $t("common.validate") }}
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'SceneLargeTextInput',
  props: ['attributes', 'number'],
  data () {
    return {
      textValue: ''
    }
  },
  methods: {
    async validate () {
      await this.$store.dispatch('storeData', {
        key: this.attributes.key,
        value: this.textValue
      })
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    }
  }
}
</script>

<style scoped lang="sass">
  .control
    margin: auto
    max-width: 250px
    margin-top: 30px
    .textarea
      min-width: 0px !important
      margin-left: calc(1px - 0.75em)

  .button
    margin-bottom: 30px
</style>
