<template>
  <div>
    <div class="columns is-mobile is-multiline" id="main-wrapper" :class="{'with-images': withImages, 'without-images': !withImages}">
      <div class="column is-narrow" id="column-a" :class="{'with-images': withImages, 'without-images': !withImages}">
      </div>
      <div class="column" id="column-b" :class="{'with-images': withImages, 'without-images': !withImages}">
        <div class="evidence columns is-mobile" :class="{'is-vcentered': !withImages}"
          v-for="(evidence, i) in evidences"
          :key="i">
          <div class="check-icon column is-narrow" :class="{'with-images': withImages, 'without-images': !withImages}">
            <i class="fas fa-check"></i>
          </div>
          <div class="evidence-text column" :class="{'with-images': withImages, 'without-images': !withImages}">
            {{ evidence }}
          </div>
        </div>
      </div>
      <div class="column is-narrow" id="column-c" :class="{'with-images': withImages, 'without-images': !withImages}">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvidencesContent',
  props: {
    withImages: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    evidences () {
      return this.$store.state.evidences
    }
  }
}
</script>

<style scoped lang="sass">

  #main-wrapper
    &.with-images
      padding: 0px 40px 40px 40px
    &.without-images
      padding: 10px

    #column-a,#column-b,#column-c
      &.with-images
        height: 600px
        padding: 0px

  #column-a
    &.with-images
      background-image: url('@/../public/img/carnet/gauche.webp')
      background-size: auto 100%
      background-repeat: no-repeat
      width: 74px
    &.without-images
      width: 0px

  #column-b
    &.with-images
      background-image: url('@/../public/img/carnet/centre.webp')
      background-size: auto 100%
      background-repeat: repeat no-repeat
      padding-top: 68px !important
      height: 532px !important
      flex-grow: 1
      flex-shrink: 0
    &.without-images
      padding-top: 30px

  #column-c
    &.with-images
      background-image: url('@/../public/img/carnet/droite.webp')
      background-size: auto 100%
      background-repeat: no-repeat
      width: 55px
    &.without-images
      width: 0px

  .evidence
    padding-left: 10px
    .check-icon, .evidence-text
      &.with-images
        line-height: 33px
      padding-top: 0px
      padding-right: 0px

    .evidence-text
      text-align: left
      padding-left: 0px
      &.with-images
        font-size: 15px
      &.without-images
        padding-top: 3px

    .check-icon
      &.with-images
        font-size: 26px
      &.without-images
        font-size: 30px
      font-weight: bold
      color: black
      padding-right: 15px
</style>
