<template>
  <div>
    <div v-if="gpsDisabled" id="disabled-gps-message">
      {{ $t("gps.disabled1") }}<br/>
      {{ $t("gps.disabled2") }}<br/><br/>

      <button class="button is-success" @click="startGps">
        {{ $t("gps.disabledLetsGo") }}
      </button>
    </div>

    <button class="button" @click="tryValidate">
      {{ $t("scene.locate.hereIAm") }}
    </button>

    <p
      ref="wrongLocationNotification"
      class="notification is-danger"
      v-if="wrongLocation">
      {{ $t("scene.locate.wrongPlace1") }}<br/><br/>
      {{ $t("scene.locate.wrongPlace2") }}
    </p>

  </div>
</template>

<script>
import GpsSceneWithTargetMixin from '@/mixins/GpsSceneWithTarget'
import PuzzleMixin from '@/mixins/Puzzle'

export default {
  name: 'SceneLocate',
  mixins: [GpsSceneWithTargetMixin, PuzzleMixin],
  props: ['attributes', 'number'],
  data () {
    return {
      isValidated: false,
      wrongLocation: false
    }
  },
  computed: {
    // Target, used by mixin to calculate distance
    target () {
      return this.attributes.target
    }
  },
  methods: {
    async validate () {
      this.playVictorySound()
      await this.$store.dispatch('storeData', {
        key: this.attributes.validationKey,
        value: 'yes'
      })
      await this.$store.dispatch('validateItem', {
        number: this.number
      })
    },
    onDistanceUpdate (value) {

    },
    async tryValidate () {
      if (this.isValidated) {
        return
      }
      if (this.distanceToTarget !== null && this.distanceToTarget < 20) {
        this.validate()
        this.isValidated = true
      } else {
        this.wrongLocation = true
        await this.$store.dispatch('storeData', {
          key: this.attributes.validationKey,
          value: 'no'
        })
        this.playFailedSound()
        const component = this
        setTimeout(function () {
          const elt = component.$refs.wrongLocationNotification
          elt.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 100)
        setTimeout(function () {
          component.wrongLocation = false
        }, 7000)
      }
    }
  }
}
</script>

<style scoped lang="sass">
  .distanceToTarget
    font-weight: bold
    font-size: 80px
    margin: auto

  .wait-for-gps, .distanceToTarget
    padding-top: 20px

  .progress
    width: 200px
    margin: auto

  .button
    margin-top: 20px
</style>
