<template>
  <div class="rankings">
    <img src="/img/parchemin-classement.png"/>
    <div class="rankings-content">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>#</th>
            <th>{{ $t("scene.rankings.name") }}</th>
            <th>{{ $t("scene.rankings.time") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in attributes.top3"
            :key="row.nickname"
            :class="{'is-player': row.is_user}"
          >
            <th><img :src="imgFromRank(row)"/></th>
            <th>{{ row.rank }}</th>
            <td>{{ row.nickname }}</td>
            <td>{{ formatTime(row.score) }}</td>
          </tr>
          <tr v-if="attributes.your_rank > 4">
            <td colspan="4" class="between-you-and-top">...</td>
          </tr>
          <tr v-if="attributes.your_rank >= 4" class="is-player">
            <th><img src="/img/rankings/player.png"/></th>
            <th>{{ attributes.your_rank }}</th>
            <td>{{ attributes.your_name }}</td>
            <td>{{ formatTime(attributes.your_score) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatElapsedTime } from '@/functions/time'

export default {
  name: 'SceneRankings',
  props: ['attributes'],
  methods: {
    formatTime (value) {
      return formatElapsedTime(value)
    },
    imgFromRank (row) {
      if (row.is_user) {
        return '/img/rankings/player.png'
      }
      return '/img/rankings/' + row.rank.toString() + '.png'
    }
  }
}
</script>

<style lang="sass" scoped>
  .rankings
    width: 100%
    max-width: 600px
    text-align: center
    position: relative
    img
      display: block
      width: 100%
    .rankings-content
      display: block
      position: absolute
      left: 0
      right: 0
      top: 50px
      white-space: pre-line
      padding: 15px 25px 30px 25px
      margin-bottom: 20px
      color: #831f1d

  .is-player
    color: #420352

  .table
    margin: auto
    width: 70%
    background-color: transparent
    tr:not(:first-of-type)
      th
        height: 40px
    th
      img
        width: 40px
    th, td
      vertical-align: middle
      border-color: #831f1d
    thead
      tr
        th
          border-width: 0px 0px 1px 0px

    tr
      .between-you-and-top
        color: #831f1d
        font-weight: bold
        font-size: 40px
        padding: 0px 0px 20px 0px
        line-height: 20px
        height: 25px

</style>
