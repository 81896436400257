<script>
import FakeInputText from '@/components/FakeInputText'

export default {
  name: 'TextInputMixin',
  props: ['attributes', 'number'],
  components: { FakeInputText },
  data () {
    return {
      textValue: '',
      wrongValue: false,
      isValid: false,
      currentChar: 0,
      invalidMessage: false,
      validityChecked: false
    }
  },
  computed: {
    words () {
      const res = []
      let startIndex = 0
      for (const splittedWord of this.expectedValue.split(' ')) {
        res.push({
          value: splittedWord,
          startIndex
        })
        startIndex += splittedWord.length + 1
      }
      return res
    }
  },
  methods: {
    setWrongValue (value) {
      const previousValue = this.wrongValue
      this.wrongValue = value
      if (value && !previousValue) {
        this.onWrongValue()
      }
    },
    onChangeText (value) {
      this.textValue = value
      this.setWrongValue(false)
    },
    onChangeValidity (value) {
      this.invalidMessage = value
    },
    nextChar (i) {
      const expectedValue = this.expectedValue
      i += 1
      // From a given character in expectedValue, what is the next character to input
      while (i < expectedValue.length && expectedValue[i] === ' ') {
        i += 1
      }
      if (i === expectedValue.length) {
        return null
      }
      return i
    },
    onEnterChar (i, value) {
      if (!value || value === ' ') {
        // Deleted content
        value = ' '
      } else {
        // Strip whitespace
        value = value.trim()
      }

      if (this.expectedValue && this.dashed && i >= this.expectedValue.length) {
        // Cannot enter more chars than expected
        return
      }

      // Save entered char
      this.setWrongValue(false)
      this.isValid = false
      const textArray = this.textValue.split('')

      textArray[i] = value
      this.textValue = textArray.join('')

      if (this.textValue.split(' ').length === this.expectedValue.split(' ').length && this.textValue.length === this.expectedValue.length) {
        // Fully entered text
        if (this.textValue.toLowerCase() === this.expectedValue.toLowerCase()) {
          this.checkValidity() // Good
        } else {
          this.setWrongValue(true)
        }
      }

      if (value === ' ') {
        // Deleted content
        return
      }

      const nextCharI = this.nextChar(i)
      if (!nextCharI) {
        // Last char
        return
      }
      // Focus and select next char
      this.currentChar = -1
      const comp = this
      this.$nextTick(function () {
        comp.currentChar = nextCharI
      })
    },
    async checkValidity () {
      this.isValid = false
      this.validityChecked = true
      const expectedValue = this.expectedValue
      const currentValue = this.textValue.toLowerCase().trim()
      if (!expectedValue && currentValue.length <= 2) {
        return
      }
      if (!expectedValue) {
        this.isValid = !this.invalidMessage
        if (!this.isValid) {
          return
        }
      }
      if (expectedValue && expectedValue.toLowerCase() !== currentValue) {
        this.setWrongValue(true)
        return
      }
      this.whenValid()
    },
    onDisplayErrorButton (el) {
      setTimeout(function () {
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    }
  }
}
</script>
