<template>
  <div>
    <div id="credits-background">
    </div>

    <div id="credits-wrapper" :style="wrapperStyle">
      <div id="credits" ref="credits">
        <div
          class="credit-section"
          v-for="section in attributes.content"
          :key="section.header">
          <div class="credit-header">
            {{ section.header }}
          </div>
          <div class="credit-content">
            {{ section.text }}
          </div>
        </div>
      </div>
    </div>
    <div id="the-end" :style="theEndStyle">
      THE END
    </div>
  </div>
</template>

<script>
const LOOP_DURATION = 50

export default {
  name: 'SceneCredits',
  data () {
    return {
      padding: 0,
      visible: false,
      creditsHeight: 1500,
      creditsEnded: false,
      theEndPadding: 5000
    }
  },
  props: ['attributes'],
  mounted () {
    this.padding = window.innerHeight
    this.theEndPadding = window.innerHeight
    this.visible = true
    setTimeout(this.startLoop, 500)
  },
  computed: {
    wrapperStyle () {
      const res = {}
      if (!this.visible) {
        res.visibility = 'hidden'
      }
      res['margin-top'] = this.padding.toString() + 'px'
      return res
    },
    theEndStyle () {
      const res = {}
      res['margin-top'] = this.theEndPadding.toString() + 'px'
      return res
    }
  },
  methods: {
    startLoop () {
      this.creditsHeight = this.$refs.credits.offsetHeight
      this.loop()
    },
    loop () {
      this.padding -= 1
      if (this.padding < -this.creditsHeight) {
        this.startTheEndLoop()
        return
      }
      setTimeout(this.loop, LOOP_DURATION)
    },
    startTheEndLoop () {
      this.creditsEnded = true
      this.theEndLoop()
    },
    theEndLoop () {
      this.theEndPadding -= 1
      if (this.theEndPadding < screen.height / 2) {
        return
      }
      setTimeout(this.theEndLoop, LOOP_DURATION)
    }
  }
}
</script>

<style lang="sass" scoped>

  #credits-background
    background-color: rgba(245, 245, 245, 0.6)
    position: absolute
    top: 0px
    left: 0px
    width: 100%
    height: 100%

  #credits-wrapper
    position: absolute
    padding: 0px 20px

  #the-end
    position: absolute
    width: 100%
    text-align: center
    font-size: 30px
    font-weight: bold

  .credit-section
    margin-bottom: 30px

    .credit-header
      font-weight: bold
      margin-bottom: 4px

    .credit-content
      white-space: pre-line
</style>
