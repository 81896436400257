<template>
  <div>
    <iframe class="has-ratio" :width="width" :height="height" :src="youtubeUrl" frameborder="0" allowfullscreen allow="autoplay"></iframe>
  </div>
</template>

<script>
export default {
  name: 'SceneYoutubeVideo',
  props: ['attributes'],
  computed: {
    youtubeUrl () {
      let res = this.attributes.url + '?controls=0&modestbranding=1&rel=0&autohide=1&showinfo=0&cc_load_policy=0&iv_load_policy=3'
      if (this.attributes.autoplay) {
        res += '&autoplay=1'
      }
      return res
    },
    width () {
      if (this.attributes.width) {
        return this.attributes.width
      }
      return '100%'
    },
    height () {
      if (this.attributes.height) {
        return this.attributes.height
      }
      return 400
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
