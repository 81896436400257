<template>
  <div id="wrapper">
    <div v-if="gpsDisabled" id="disabled-gps-message">
      {{ $t("gps.disabled1") }}<br/>
      {{ $t("gps.disabled2") }}<br/><br/>

      <button class="button is-success" @click="startGps">
        {{ $t("gps.disabledLetsGo") }}
      </button>
    </div>
    <div
      id="instructions"
      class="notification is-info"
      v-if="gpsDisabled === false"
      :class="{'disable-pointer-events': !huntingCoordinates}"
    >
      <p v-if="huntingResultNothing">
        {{ $t("hunting.areYouSure") }}<br/>
        {{ $t("hunting.iDontSee") }}<br/><br/>
        <button class="button is-default" @click="goToDashboard">
          {{ $t("common.abandon") }}
        </button>
        <button class="button is-primary" @click="modifyHuntCoordinates">
          {{ $t("common.retry") }}
        </button>
      </p>
      <p v-if="huntingResultAlready">
        {{ $t("hunting.alreadyFound") }}<br/><br/>
        <button class="button is-default" @click="goToDashboard">
          {{ $t("common.abandon") }}
        </button>
        <button class="button is-primary" @click="modifyHuntCoordinates">
          {{ $t("common.retry") }}
        </button>
      </p>
      <p v-if="huntingResultAtMax">
        {{ $t("hunting.yesButEnough") }}<br/><br/>
        <button class="button is-default" @click="goToDashboard">
          {{ $t("common.abandon") }}
        </button>
        <button class="button is-primary" @click="modifyHuntCoordinates">
          {{ $t("common.retry") }}
        </button>
      </p>
      <p v-if="huntingResultFound">
        {{ $t("hunting.congrats") }}<br/><br/>
        {{ $t("hunting.newElements") }}
        <br/>
        <button class="button is-default" @click="goToDashboard">
          {{ $t("common.goBack") }}
        </button>
      </p>
      <p v-if="!huntingResult && !huntingCoordinates">
        {{ $t("hunting.clickOnMap") }}
      </p>
      <p v-if="!huntingResult && huntingCoordinates">
        <button class="button is-default" @click="modifyHuntCoordinates">
          {{ $t("common.modify") }}
        </button>
        <button class="button is-primary" @click="checkHuntCoordinates">
          {{ $t("common.validate") }}
        </button>
      </p>
    </div>
    <div id="waiting-for-gps-signal" v-if="gpsDisabled === undefined && !position">
      <img src="/img/gps-signal.png"/>
      <progress class="progress is-small is-default" max="100">15%</progress>
    </div>
    <div id="map" v-if="gpsDisabled === false">
      <ol-map style="height: 100%" @click="onClick" :moveTolerance="999999">
        <ol-view ref="view" :center="centerCoordinates" :rotation="currentOrientation" projection="EPSG:4326" :zoom="19" :maxZoom="19" :minZoom="19"/>

        <ol-tile-layer ref="osmLayer" title="OSM">
          <ol-source-osm />
        </ol-tile-layer>

        <ol-vector-layer>
          <ol-source-vector>
            <ol-feature v-if="position">
              <ol-geom-point :coordinates="[position.coords.longitude, position.coords.latitude]"></ol-geom-point>
              <ol-style>
                <ol-style-icon src="/img/gps-current-position.png" :scale="0.15"></ol-style-icon>
              </ol-style>
            </ol-feature>
            <ol-feature>
                <ol-geom-point :coordinates="huntingCoordinates" v-if="huntingCoordinates"></ol-geom-point>
                <ol-style>
                    <ol-style-circle :radius="20">
                        <ol-style-fill :color="'rgba(255, 0, 0, 0.5)'"></ol-style-fill>
                    </ol-style-circle>
                </ol-style>
            </ol-feature>
          </ol-source-vector>
        </ol-vector-layer>
      </ol-map>
    </div>
  </div>
</template>

<script>
import GpsSceneMixin from '@/mixins/GpsScene'
import PuzzleMixin from '@/mixins/Puzzle'

export default {
  name: 'HuntingMap',
  mixins: [GpsSceneMixin, PuzzleMixin],
  props: ['huntingKey'],
  data () {
    return {
      watchUpdates: false,
      huntingCoordinates: null,
      huntingResult: null
    }
  },
  computed: {
    centerCoordinates () {
      if (!this.position) {
        return []
      }
      // Center map on current position
      return [this.position.coords.longitude, this.position.coords.latitude]
    },
    huntingResultNothing () {
      // Nothing found
      if (!this.huntingResult) {
        return false
      }
      return this.huntingResult.nb_new_items_found + this.huntingResult.nb_items_already_found + this.huntingResult.nb_items_at_max === 0
    },
    huntingResultFound () {
      // YaY ! New location found !
      if (!this.huntingResult) {
        return false
      }
      return this.huntingResult.nb_new_items_found > 0
    },
    huntingResultAlready () {
      // Oh. You already found this one
      if (!this.huntingResult) {
        return false
      }
      return this.huntingResult.nb_new_items_found === 0 && this.huntingResult.nb_items_already_found > 0
    },
    huntingResultAtMax () {
      // Oh. Enough already with this one !
      if (!this.huntingResult) {
        return false
      }
      return this.huntingResult.nb_new_items_found === 0 && this.huntingResult.nb_items_already_found === 0 && this.huntingResult.nb_items_at_max > 0
    }
  },
  mounted () {
    this.huntingResult = null
  },
  methods: {
    goToDashboard () {
      this.$emit('go-to-dashboard', { go: true })
    },
    onClick (evt) {
      if (!evt.coordinate || this.huntingCoordinates) {
        return
      }
      this.huntingCoordinates = evt.coordinate
    },
    onDistanceUpdate (distance) {
    },
    modifyHuntCoordinates () {
      this.huntingCoordinates = null
      this.huntingResult = null
    },
    async checkHuntCoordinates () {
      this.huntingResult = await this.$store.dispatch('checkHuntCoordinates',
        {
          huntingKey: this.huntingKey,
          value: [this.huntingCoordinates[1], this.huntingCoordinates[0]]
        })
      if (this.huntingResult.nb_new_items_found > 0) {
        this.playVictorySound()
      } else {
        this.playFailedSound()
      }
    }
  }
}
</script>

<style scoped lang="sass">
  #wrapper
    height: 100%

  #map
      width: 100%
      height: 100%
      background-color: white

  .wait-for-gps
    padding-top: 20px

  .progress
    width: 200px
    margin: auto

#instructions, #waiting-for-gps-signal
  position: absolute
  z-index: 2000
  width: 100%
  text-align: center
  ul
    text-align: left

#waiting-for-gps-signal
  margin-top: 40px
  img
    display: block
    width: 60px
    margin: auto
  .progress
    margin: auto
    margin-top: 5px
    width: 200px

#disabled-gps-message
  padding-top: 100px

#instructions
  bottom: 0px
  margin-bottom: 10px
  text-align: center
  padding: 10px 50px
  box-sizing: border-box
  color: black
  background-color: rgba(255, 255, 255, 0.5)
  p
    margin: 0px
    font-weight: bold
    span
      font-weight: normal
      display: block
      padding-top: 5px
  button
    margin: 10px 0px 0px 0px
  button:nth-of-type(1)
    margin-right: 20px

.disable-pointer-events
  pointer-events: none

</style>
