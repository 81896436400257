<template>
  <div class="character-speaking-wrapper" :style="wrapperStyle">
    <div
      class="character-speaking notification is-info"
      :style="textStyle"
      :class="textClass"
    >
      {{ attributes.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SceneCharacterSpeaking',
  props: ['attributes'],
  computed: {
    textStyle () {
      const res = {
        'background-color': this.attributes.color,
        color: this.attributes.textColor
      }
      if (this.attributes.text.length < 60) {
        res.width = (this.attributes.text.length * 9).toString() + 'px'
      }
      return res
    },
    textClass () {
      if (this.attributes.side === 'full') {
        return 'fullwidth'
      }
      return ''
    },
    wrapperStyle () {
      const res = {}
      if (this.attributes.side === 'left') {
        res['text-align'] = 'left'
      } else {
        res['text-align'] = 'right'
      }
      return res
    }
  }
}
</script>

<style lang="sass">

.character-speaking-wrapper
  width: 100%
  box-sizing: border-box
  padding: 30px 30px 0px 30px

  .character-speaking
    display: inline-block
    border-radius: 10px
    padding: 10px 20px
    max-width: 600px
    white-space: pre-line
    text-align: left

    @media (max-width: 640px)
      max-width: 70%

  .fullwidth
    @media (max-width: 640px)
      max-width: 85%

.zone:not(.main-zone)
  // Hack : first-of-class
  .character-speaking-wrapper
    padding-top: 0px
  .character-speaking-wrapper ~ .character-speaking-wrapper
    padding-top: 20px

</style>
