<template>
  <div id="image-to-tap">
    <div
      v-if="wrongOrientation"
      class="please-rotate notification is-primary">
      {{ $t("common.pleaseRotate1") }} <strong>{{ $t("common.pleaseRotate2") }}</strong><br/><br/><br/>
      {{ $t("common.pleaseRotate3") }}
    </div>
    <div
      v-if="!wrongOrientation">
      <div id="scrollable-icon" v-if="scrollable">
        <i class="fas fa-up-down-left-right"></i>
      </div>
      <div id="floating-text" v-if="attributes.floatingText">
        {{ attributes.floatingText }}
      </div>
      <div id="wrong-answer" v-if="wrongAnswer">
        {{ $t("scene.imagetotap.nope") }}
      </div>
      <div id="counter" v-if="isMultipleTaps">
        {{ foundTargets.length }} / {{ attributes.targets.length }}
      </div>
      <div class="parent" :style="{'width': imageWidth + 'px', 'height': imageHeight + 'px'}">
        <img :src="attributes.url" ref="theImg" @click="onClick" class="child"/>
        <div
          class="found-item"
          v-for="target in foundTargets"
          :key="target.x.toString() + target.y.toString()"
        >
          <img
            :src="attributes.tappedImageFile"
            :style="{'left': target.x - tappedImageFileWidth/2 + 'px', 'top': target.y - tappedImageFileHeight/2 + 'px'}"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PuzzleMixin from '@/mixins/Puzzle'

export default {
  name: 'ImageToTapMixin',
  mixins: [PuzzleMixin],
  props: ['attributes', 'number'],
  data () {
    return {
      wrongAnswer: false,
      isWide: false,
      imageWidth: 0,
      imageHeight: 0,
      foundTargets: [], // Only for multiples
      tappedImageFileWidth: 0, // Only for multiples
      tappedImageFileHeight: 0 // Only for multiples
    }
  },
  watch: {
    lastDisplayedClue (value) {
      if (!this.isMultipleTaps) {
        return
      }
      if (this.attributes.enableHelperAtClue === -1) {
        // Not defined
        return false
      }
      if (value >= this.attributes.enableHelperAtClue) {
        let nbHelp = 0
        for (const target of this.attributes.targets) {
          if (!this.foundTargets.includes(target)) {
            this.foundTargets.push(target)
            nbHelp += 1
          }
          if (nbHelp === 3) {
            return
          }
        }
      }
    }
  },
  computed: {
    lastDisplayedClue () {
      return this.$store.state.lastDisplayedClue
    },
    isMultipleTaps () {
      if (this.attributes.targets !== undefined) {
        return true
      }
      return false
    },
    scrollable () {
      return this.attributes.scrollable
    },
    wrongOrientation () {
      if (!this.attributes.forceOrientation) {
        return false
      }
      if (this.attributes.forceOrientation === 'landscape' && !this.isWide) {
        return true
      }
      if (this.attributes.forceOrientation === 'portrait' && this.isWide) {
        return true
      }
      return false
    }
  },
  methods: {
    onResize () {
      this.isWide = window.innerWidth > window.innerHeight
    }
  },
  mounted () {
    this.isWide = window.innerWidth > window.innerHeight
    if (this.attributes.initialScrollTo) {
      const theImg = this.$refs.theImg
      const scrollableParent = theImg.closest('.scrollable-zone')
      const x = this.attributes.initialScrollTo.x
      const y = this.attributes.initialScrollTo.y
      theImg.onload = function () {
        scrollableParent.scrollLeft = x
        scrollableParent.scrollTop = y
      }
    }
    const component = this
    if (this.attributes.tappedImageFile) {
      const img = new Image()
      img.onload = function () {
        component.tappedImageFileWidth = this.width
        component.tappedImageFileHeight = this.height
      }
      img.src = this.attributes.tappedImageFile
    }
    const mainImg = new Image()
    mainImg.onload = function () {
      component.imageWidth = this.width
      component.imageHeight = this.height
    }
    mainImg.src = this.attributes.url
    window.addEventListener('resize', this.onResize, false)
  },
  unmounted () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="sass">

#image-to-tap

  .please-rotate
    margin-top: 150px

  #scrollable-icon
    position: absolute
    width: 100%
    text-align: center
    opacity: 0.3
    margin-top: 60px
    pointer-events: none
    .fa-up-down-left-right
      font-size: 80px
      color: #844
      pointer-events: none

  #floating-text
    position: absolute
    pointer-events: none
    width: 50%
    text-align: center
    opacity: 0.8
    padding: 5px
    color: rgba(255, 255, 255, 0.9)
    font-weight: 600
    background-color: rgba(86, 43, 170, 0.3)

  #wrong-answer
    position: absolute
    pointer-events: none
    left: 25%
    right: 25%
    top: 100px
    z-index: 10000
    text-align: center
    opacity: 0.8
    padding: 10px 30px
    color: rgba(255, 255, 255, 0.9)
    font-weight: 600
    background-color: rgba(255, 0, 0, 0.6)

  #counter
    position: absolute
    pointer-events: none
    right: 10px
    top: 10px
    z-index: 10000
    text-align: center
    opacity: 0.8
    padding: 10px 30px
    color: rgba(0, 0, 0, 0.9)
    font-weight: 600
    background-color: rgba(255, 255, 255, 0.6)

  .found-item
    z-index: 4
    img
      position: absolute

  .parent
    display: flex
    position: relative
    z-index: 2

  .parent:after
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 2

  .child
    position: absolute
    max-width: 100%
    max-height: 100%
    z-index: 3

</style>
