<template>
  <div>
    <ul>
      <li v-for="choice in attributes.choices" :key="choice.key">
        <button
          class="button"
          :disabled="validated"
          :class="{'selected': selectedValue === choice.key}"
          @click="select(choice)"
        >{{ choice.text }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
import UniqueChoiceSceneMixin from '@/mixins/UniqueChoiceScene'

export default {
  name: 'SceneUniqueChoice',
  mixins: [UniqueChoiceSceneMixin]
}
</script>

<style scoped lang="sass">
  ul
    padding-left: 0
    margin-bottom: 0
    li
      list-style: none
      margin-top: 5px
      button.selected
          z-index: 1006
      button
        white-space: pre-line
</style>
