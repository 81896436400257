<template>
  <div class="wrapper">
    <ul>
      <li>{{ $t("scene.scoreDetails.resolutionTime") }} <b>{{ formatTime(attributes.time_elapsed) }}</b></li>
      <li>{{ $t("scene.scoreDetails.penalties") }} <b>{{ formatTime(attributes.time_penalties) }}</b></li>
      <li>{{ $t("scene.scoreDetails.bonus") }} <b>-{{ formatTime(attributes.time_bonuses) }}</b></li>
    </ul>
  </div>
</template>

<script>
import { formatElapsedTime } from '@/functions/time'

export default {
  name: 'SceneScoreDetails',
  props: ['attributes'],
  methods: {
    formatTime (value) {
      if (value === 0) {
        return '-'
      }
      return formatElapsedTime(value, true)
    }
  }
}
</script>

<style lang="sass" scoped>
  .wrapper
    padding: 20px 0px 5px 0px
    text-align: left
    padding-left: 55px
    ul
      padding-left: 0px
      li
        padding-bottom: 15px

</style>
