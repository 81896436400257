<template>
  <div>
    <button
      class="button"
      :disabled="validated"
      :class="{'selected': validated}"
      @click="select()"
    >{{ attributes.text }}</button>
  </div>
</template>

<script>
import screenfull from 'screenfull'

export default {
  name: 'SceneActionButton',
  props: ['attributes', 'number'],
  data () {
    return {
      validated: false
    }
  },
  methods: {
    select () {
      this.validated = true
      this.$store.dispatch('validateItem', {
        number: this.number
      })
      if (this.attributes.requestFullScreen && screenfull.isEnabled) {
        screenfull.request()
      }
    }
  }
}
</script>

<style scoped lang="sass">
  button
    margin: 20px 0px
    white-space: pre-line
</style>
