// A zone is an area within a layout, containing components
class Zone {
  constructor () {
    this.components = []
    this.background = null
    this.backgroundAnchor = 'top center'
  }

  addComponent (key, attributes, number) {
    this.components.push(new Component(key, attributes, number))
  }

  clear () {
    this.components = []
  }
}

// A component is a VueJS component rendered within a zone
class Component {
  constructor (key, attributes, number) {
    this.key = key
    this.number = number
    this.attributes = attributes
  }
}

export default Zone
