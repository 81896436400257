import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireSceneComponent = require.context(
  // The relative path of the components folder
  './scene',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /.+\.(vue|js)$/
)

const requireLayoutComponent = require.context(
  // The relative path of the components folder
  './layout',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /.+\.(vue|js)$/
)

export function registerComponents (app) {
  for (const req of [requireSceneComponent, requireLayoutComponent]) {
    req.keys().forEach(fileName => {
      // Get component config
      const componentConfig = req(fileName)

      // Get PascalCase name of component
      const componentName = upperFirst(
        camelCase(
          // Gets the file name regardless of folder depth
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        )
      )
      // Register component globally
      app.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
      )
    })
  }
}
