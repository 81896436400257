import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import { registerComponents } from './components/register_scene_components'
import * as Sentry from '@sentry/vue'
import OpenLayersMap from 'vue3-openlayers'
import i18n from './i18n'

require('@/assets/main.scss')

const socket = io(process.env.VUE_APP_API_HOST, {
  reconnection: true,
  reconnectionDelay: 1000,
  maxReconnectionAttempts: Infinity,
  transports: JSON.parse(process.env.VUE_APP_API_TRANSPORT)
})

let app = createApp(App)

if (process.env.VUE_APP_SENTRY_URL) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_URL,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration({ router })
    ],
    ignoreErrors: ['GPS : User denied Geolocation', 'Permissions::query does not support this API'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.2
  })
}

app.use(store).use(router).use(VueSocketIOExt, socket, {
  store
}).use(OpenLayersMap)

console.log('connecting to backend ', process.env.VUE_APP_API_HOST, process.env.VUE_APP_API_TRANSPORT)

registerComponents(app)

// i18n
app.use(i18n)

app = app.mount('#app')

store.$app = app
