<template>
  <div class="columns notebook is-mobile">
    <div class="column is-narrow">
      <div
        class="button-wrapper"
        @touchstart="moveBefore()"
        v-if="hasEntriesBefore">
        <i class="fas fa-chevron-left notebook-previous">
        </i>
      </div>
    </div>
    <div class="column main-column">
      <div class="notification is-success" :style="textStyle">
        <div class="notebook-title">
          {{ currentEntry.title }}
        </div>
        <div class="notebook-text">
          {{ currentEntry.text }}
        </div>
      </div>
    </div>
    <div class="column is-narrow">
      <div
          class="button-wrapper"
          @touchstart="moveNext()"
          v-if="hasEntriesAfter">
        <i class="fas fa-chevron-right notebook-next">
        </i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SceneNoteBook',
  props: ['attributes'],
  data () {
    return {
      entryNo: 0
    }
  },
  computed: {
    hasEntriesBefore () {
      return this.entryNo >= 1
    },
    hasEntriesAfter () {
      return this.entryNo < this.attributes.entries.length - 1
    },
    currentEntry () {
      return this.attributes.entries[this.entryNo]
    },
    textStyle () {
      const res = {
        'background-color': this.attributes.color,
        color: 'white'
      }
      return res
    }
  },
  methods: {
    moveNext () {
      this.entryNo += 1
    },
    moveBefore () {
      this.entryNo -= 1
    }
  }
}
</script>

<style lang="sass" scoped>
  .notification
    white-space: pre-line
    padding: 15px

  .notebook-title
    font-weight: bold
    padding-bottom: 10px

  .notebook-text
    text-align: left
    font-size: 14px

  .notebook-previous, .notebook-next
    font-size: 26px
    font-weight: bold
    cursor: pointer

  .notebook-previous
    margin-left: 15px

  .notebook-next
    margin-right: 15px

  .button-wrapper
    margin-top: 20px

  .main-column
    padding: 10px 0px

</style>
