<template>
  <div>
    <div class="notification is-danger">
      {{ $t("error.doesNotExist.invalid") }}<br/><br/>
    </div>
    <br/>
    <div v-if="!reportSent">
      {{ $t("error.report.notNormal") }}<br/>
      {{ $t("error.report.leaveAMessage") }}<br/><br/>

      <textarea class="textarea is-warning" rows="5" v-model="reportText">
      </textarea><br/><br/>
      <a class="button is-warning" @click="reportError">
        {{ $t("error.report.send") }}
      </a>
    </div>
    <div v-else>
      {{ $t("error.report.thanks") }}
    </div>
    <br/><br/>
  </div>
</template>

<script>
import ReportErrorMixin from '@/mixins/ReportError'

export default {
  name: 'TicketDoesNotExist',
  mixins: [ReportErrorMixin]
}
</script>

<style scoped lang="sass">
  .notification
    margin: auto
    margin-top: 50px
  .textarea
    min-width: 0px !important
    box-sizing: border-box
</style>
