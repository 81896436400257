<template>
  <div>
    <p
      class="notification is-danger"
      v-if="invalidMessage && validityChecked">
        {{ invalidMessage }}
    </p>
    <div class="field is-grouped" v-if="!expectedValue || !dashed">
      <p class="control is-expanded">
        <FakeInputText
          :placeholder="attributes.placeholder"
          :modelValue="textValue"
          :type="attributes.inputType"
          @update:modelValue="onChangeText"
          @update:invalidMessage="onChangeValidity">
        </FakeInputText><br/>
      </p>
      <p class="control">
        <a class="button is-info" @click="checkValidity">
          <span class="icon is-small">
            <i class="fas fa-check"></i>
          </span>
        </a>
      </p>
    </div>
    <div v-if="expectedValue && dashed">
      <div class="field is-grouped word"
        v-for="word in words"
        :key="word.startIndex">
        <span v-for="(expectedChar, ichar) of word.value" :key="ichar" class="char-container">
          <FakeInputText
            :maxlength="1"
            :ref="'char' + (word.startIndex + ichar).toString()"
            @update:modelValue="onEnterChar(word.startIndex + ichar, $event)"
            :modelValue="textValue[word.startIndex + ichar]"
            :getFocus="word.startIndex + ichar === currentChar">
          </FakeInputText>
        </span>
      </div>
    </div>
    <p
      class="notification is-danger"
      :ref="onDisplayErrorButton"
      v-if="wrongValue">
      {{ $t("common.nope") }}
      <span v-if="attributes.wrongValuePenalty">
        <br/>
        ({{ $t("common.penalty") }} + {{ attributes.wrongValuePenalty }}s)
      </span>
    </p>
    <div
      class="button is-default"
      v-if="isValid && expectedValue"
      @click="validate"
      :ref="onDisplaySuccessButton">
      {{ $t("common.congrats") }}&nbsp;<i class="fa fa-angle-right"></i>
    </div>
  </div>
</template>

<script>
import FakeInputText from '@/components/FakeInputText'
import PuzzleMixin from '@/mixins/Puzzle'
import TextInputMixin from '@/mixins/TextInput'

export default {
  name: 'SceneTextInput',
  mixins: [PuzzleMixin, TextInputMixin],
  props: ['attributes', 'number'],
  components: { FakeInputText },
  created () {
    if (this.attributes.expectedValue && this.attributes.dashed) {
      // Initialize textValue with spaces
      this.textValue = ' '.repeat(this.expectedValue.length)
    }
  },
  computed: {
    expectedValue () {
      return this.attributes.expectedValue
    },
    dashed () {
      return this.attributes.dashed
    }
  },
  methods: {
    onWrongValue () {
      // Becomes wrong. If there is a penalty, notice it to the backend
      if (this.attributes.wrongValuePenalty) {
        this.$store.dispatch('addTimePenalty', {
          seconds: this.attributes.wrongValuePenalty
        })
      }
      if (!this.attributes.dashed) {
        this.playFailedSound()
      }
    },
    async whenValid () {
      if (this.attributes.key) {
        await this.$store.dispatch('storeData', {
          key: this.attributes.key,
          value: this.textValue
        })
      }
      if (this.expectedValue) {
        this.isValid = true
      } else {
        this.validate()
      }
    },
    onDisplaySuccessButton (el) {
      if (el) {
        this.playVictorySound()
      }
      setTimeout(function () {
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    },
    validate () {
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    }
  }
}
</script>

<style scoped lang="sass">
  .field
    max-width: 300px
    margin: auto

  .notification
    width: 200px
    margin: auto
    margin-top: 15px
    padding: 10px

  .button.is-default
    margin-top: 20px
    margin-bottom: 30px

  .word
    margin: 20px 20px 0px 20px
    justify-content: center !important
    max-width: none

    .char-container
      text-align: center
      margin-right: 2px
      input
        text-align: center
        width: 18px
        text-transform: uppercase
        font-weight: bold

</style>
