<template>
  <div v-html="attributes.svg" class="qrcode-container">
  </div>
</template>

<script>
export default {
  name: 'SceneQrCode',
  props: ['attributes']
}
</script>

<style lang="sass">
  .qrcode-container
    svg
      background: white
</style>
