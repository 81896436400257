<template>
  <div class="modal" :class="{'is-active': displayed}" id="hunting-modal">
    <div class="modal-background"></div>
    <div class="modal-content" v-if="huntings && huntingKey && displayed">
      <HuntingDashboard :huntingKey="huntingKey" v-if="mode === 'dashboard'" @go-to-map="mode = 'map'">
      </HuntingDashboard>
      <HuntingMap :huntingKey="huntingKey" v-if="mode === 'map'" @go-to-dashboard="mode = 'dashboard'">
      </HuntingMap>
    </div>

    <button
      class="modal-close is-large"
      aria-label="close"
      @click="closeModal">
    </button>
  </div>
</template>

<script>
import HuntingDashboard from '@/components/hunting/HuntingDashboard'
import HuntingMap from '@/components/hunting/HuntingMap'

export default {
  name: 'HuntingModal',
  data () {
    return {
      mode: 'dashboard'
    }
  },
  computed: {
    huntings () {
      return this.$store.state.huntings
    }
  },
  components: { HuntingDashboard, HuntingMap },
  props: ['displayed', 'huntingKey'],
  methods: {
    closeModal () {
      this.$emit('update:displayed', false)
      this.mode = 'dashboard'
    }
  }
}
</script>

<style scoped lang="sass">
  .modal
    z-index: 110

  .modal-content
    height: 100%
    max-height: none

  .modal-background
    background-color: rgba(255, 255, 255, 0.95)

  .modal-close::before, .modal-close::after
    background-color: black

</style>
