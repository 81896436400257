import { createRouter, createWebHistory } from 'vue-router'
import GatheringYetToCome from '@/views/GatheringYetToCome.vue'
import Home from '@/views/Home.vue'
import HelpIphoneGps from '@/views/help/IphoneGps.vue'
import HelpChromeGps from '@/views/help/ChromeGps.vue'
import HelpFirefoxGps from '@/views/help/FirefoxGps.vue'
import HelpIphoneAccelerometer from '@/views/help/IphoneAccelerometer.vue'
import SelectLanguage from '@/views/SelectLanguage.vue'
import Ticket from '@/views/Ticket.vue'
import TicketDoesNotExist from '@/views/TicketDoesNotExist.vue'
import TicketIsConsumed from '@/views/TicketIsConsumed.vue'
import VisitorEntry from '@/views/VisitorEntry.vue'
import store from '@/store'
import axiosInstance from '@/api'
import UAParser from 'ua-parser-js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/gathering-yet-to-come/:ticketUid/:eventDate',
    name: 'GatheringYetToCome',
    component: GatheringYetToCome
  },
  {
    path: '/help/iphone-gps/:ticketUid',
    name: 'HelpIphoneGps',
    component: HelpIphoneGps
  },
  {
    path: '/help/chrome-gps/:ticketUid',
    name: 'HelpChromeGps',
    component: HelpChromeGps
  },
  {
    path: '/help/firefox-gps/:ticketUid',
    name: 'HelpFirefoxGps',
    component: HelpFirefoxGps
  },
  {
    path: '/help/iphone-accelerometer/:ticketUid',
    name: 'HelpIphoneAccelerometer',
    component: HelpIphoneAccelerometer
  },
  {
    path: '/ticket/does-not-exist',
    name: 'TicketDoesNotExist',
    component: TicketDoesNotExist
  },
  {
    path: '/ticket/is-consumed',
    name: 'TicketIsConsumed',
    component: TicketIsConsumed
  },
  {
    path: '/ticket/:ticketUid/language',
    name: 'SelectLanguage',
    component: SelectLanguage
  },
  {
    path: '/ticket/:ticketUid/entry',
    name: 'VisitorEntry',
    component: VisitorEntry
  },
  {
    path: '/ticket/:ticketUid',
    name: 'Ticket',
    component: Ticket
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  // Before accessing to a ticket :
  // - Global infos about ticket needs to be retrieved from server
  // - Language needs to be selelected
  // - Permissions need to be authorized
  if ((to.name === 'Ticket' || to.name === 'VisitorEntry' || to.name === 'SelectLanguage') && to.params.ticketUid) {
    if (!store.state.globalInfosLoaded) {
      // Load main ticket infos
      try {
        const parser = new UAParser(navigator.userAgent)
        const parserResults = parser.getResult()
        const serverData = await axiosInstance.post('/fetch-main-ticket-infos/' + to.params.ticketUid, {
          browser: parserResults.browser,
          os: parserResults.os,
          device: parserResults.device
        })

        if (serverData.data.error === 'GATHERING_YET_TO_COME') {
          return {
            name: 'GatheringYetToCome',
            params: {
              eventDate: new Date(serverData.data.eventDate).toLocaleDateString(),
              ticketUid: to.params.ticketUid
            }
          }
        }
        store.commit('SET_MAIN_INFOS', serverData.data)
      } catch (err) {
        console.log('INIT ERROR')
        if (err.response.status === 403) {
          // Ticket is not valid anymore
          return { name: 'TicketIsConsumed' }
        } else if (err.response.status === 404) {
          // Ticket does not exist
          return { name: 'TicketDoesNotExist' }
        } else {
          console.log('Unexpected init error ', err)
        }
      }
    }
  }
  if ((to.name === 'Ticket' || to.name === 'VisitorEntry') && to.params.ticketUid) {
    if (!store.state.mainInfos.language) {
      console.log('no language yet', store.state.mainInfos.language)
      return {
        name: 'SelectLanguage',
        params: {
          ticketUid: to.params.ticketUid
        },
        query: {
          redirectTo: to.fullPath
        }
      }
    }
  }
  if (to.name === 'Ticket' && to.params.ticketUid) {
    if (!store.state.gotPermissions) {
      console.log('no permissions yet', store.state.gotPermissions)
      return {
        name: 'VisitorEntry',
        params: {
          ticketUid: to.params.ticketUid
        },
        query: {
          redirectTo: to.fullPath
        }
      }
    }
  }
  return true
})

export default router
