<script>
export default {
  name: 'GpsSceneMixin',
  data () {
    return {
      watchUpdates: true,
      position: null,
      gpsWatchId: null,
      gpsDisabled: undefined,
      currentOrientation: 0,
      currentOrientationDegrees: 0,
      magnetometer: undefined
    }
  },
  methods: {
    onPositionChanged (pos) {
      this.position = pos
      this.gpsDisabled = false
      this.onPositionChangedAction()
    },
    onPositionChangedAction () {
    },
    onGpsDisabled (error) {
      this.gpsDisabled = true
      console.log('GPS is disabled')
      console.log(error.code)
      console.log(error.message)
    },
    startGps () {
      console.log('startGps')
      const options = {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 6000 /* 6 sec */
      }
      navigator.geolocation.getCurrentPosition(this.onPositionChanged, this.onGpsDisabled, options)
      if (this.watchUpdates) {
        this.gpsWatchId = navigator.geolocation.watchPosition(this.onPositionChanged, null, options)
      }
    },
    onMagnetometerUpdate () {
      const q = this.magnetometer.quaternion
      if (!q) {
        return
      }
      var heading = Math.atan2(2*q[0]*q[1] + 2*q[2]*q[3], q[3]*q[3] + q[0]*q[0] - q[1]*q[1] - q[2]*q[2])*(180/Math.PI) // eslint-disable-line

      if (heading < 0) {
        heading = 360 + heading
      }

      this.currentOrientationDegrees = heading
      // Degrees to radians
      heading = heading * 0.0174533
      this.currentOrientation = heading
    }
  },
  mounted () {
    this.startGps()
    const options = { frequency: 30, referenceFrame: 'screen' }
    const comp = this
    if (navigator.permissions !== undefined) {
      navigator.permissions.query({ name: 'magnetometer' }).then(function (result) {
        console.log('magnetometer permission ', result.state)
        if (result.state === 'granted') {
          comp.magnetometer = new AbsoluteOrientationSensor(options) // eslint-disable-line no-undef
          console.log('start magnetometer', comp.magnetometer)
          comp.magnetometer.addEventListener('reading', comp.onMagnetometerUpdate)
          comp.magnetometer.addEventListener('error', function (e) { console.log('magnetometer error', e) })
          comp.magnetometer.start()
        }
      })
    }
    if (screen.orientation !== undefined && screen.orientation.lock !== undefined) {
      screen.orientation.lock('portrait')
    }
  },
  unmounted () {
    console.log('unmounted', this.gpsWatchId)
    if (this.watchUpdates) {
      navigator.geolocation.clearWatch(this.gpsWatchId)
    }
    if (this.magnetometer) {
      this.magnetometer.removeEventListener('reading', this.onMagnetometerUpdate)
    }
    if (screen.orientation !== undefined && screen.orientation.unlock !== undefined) {
      screen.orientation.unlock()
    }
  }
}
</script>
