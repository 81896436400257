<template>
  <div>
    <div
      v-for="choice in attributes.choices"
      :key="choice.key"
      class="image-to-select"
      :class="{'selected': selectedValue === choice.key}"
    >
      <img :src="choice.url"
        @click="select(choice)"
        :disabled="validated"
        :style="imgStyle"/>
    </div>
  </div>
</template>

<script>
import UniqueChoiceSceneMixin from '@/mixins/UniqueChoiceScene'

export default {
  name: 'SceneUniqueChoiceImage',
  mixins: [UniqueChoiceSceneMixin],
  computed: {
    imgStyle () {
      const res = {
        width: this.attributes.width,
        'max-width': '90%'
      }
      return res
    }
  }
}
</script>

<style scoped lang="sass">
  .image-to-select
    display: inline-block
    padding: 20px

    &.selected
      img[disabled="true"]
        opacity: 1
        border: solid 4px #48c774

    img
      cursor: pointer

      &[disabled="true"]
        opacity: 0.5

</style>
