<template>
  <div>
    <div class="notification is-info">
      {{ $t("error.gatheringToCome.plannedOn") }} <strong>{{ eventDate }}</strong><br/><br/>
      <br/><br/>
      {{ $t("error.gatheringToCome.cannotBeforeDate") }}
      <br/><br/>
      {{ $t("error.gatheringToCome.patience") }}
    </div>
    <br/><br/>
    <div id="retry-ticket">
      <button type="button" class="button is-default" @click="move_back_to_ticket()">
        {{ $t("common.retry") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    eventDate () {
      return this.$route.params.eventDate
    },
    ticketUid () {
      return this.$route.params.ticketUid
    }
  },
  methods: {
    move_back_to_ticket () {
      this.$router.push({
        name: 'Ticket',
        params: { ticketUid: this.ticketUid }
      })
    }
  }
}
</script>

<style scoped lang="sass">
  .notification
    margin: auto
    margin-top: 50px
</style>
