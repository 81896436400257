<script>
import ImageToTapMixin from '@/mixins/ImageToTap'

export default {
  name: 'SceneImageToTap',
  mixins: [ImageToTapMixin],
  methods: {
    onClick (evt) {
      const target = this.attributes.target
      const targetDistance = Math.sqrt((evt.offsetX - target.x) ** 2 + (evt.offsetY - target.y) ** 2)
      if (targetDistance < 20) {
        // Success
        this.playVictorySound()
        this.$store.dispatch('validateItem', {
          number: this.number
        })
      } else {
        // Failure
        this.wrongAnswer = true
        const component = this
        setTimeout(function () {
          component.wrongAnswer = false
        }, 3000)
        this.playFailedSound()
      }
    }
  }
}
</script>
