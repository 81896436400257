<script>
export default {
  name: 'PuzzleMixin',
  methods: {
    playVictorySound () {
      const track = this.$store.state.audio.tracks[2]

      track.volume = 1
      track.play()
      track.src = '/sound/victoire.mp3'
    },
    playFailedSound () {
      const track = this.$store.state.audio.tracks[2]

      track.volume = 1
      track.play()
      track.src = '/sound/failed.mp3'
    }
  }
}
</script>
