<template>
  <div>
    <div
      class="notification is-danger permissions-missing"
      v-if="gotPermissionsOnce && !allPermissionsOk">
      {{ $t("getPermissions.missingPermissions") }}
      <ul>
        <li v-for="permission in missingPermissions" :key="permission" v-html="$t(permissionTexts[permission])">
        </li>
      </ul>
    </div>
    <div v-if="gotPermissionsOnce && !allPermissionsOk">
      <div class="notification is-warning geolocation-notification" v-if="errorDuringPermissions">
        <div id="error-during-permissions">
          {{ $t("getPermissions.failedPermissions1") }}<br/>
          {{ $t("getPermissions.failedPermissions2") }}
        </div>
      </div>
      <div class="notification is-warning" v-if="geoLocationIssue || accelerometerIssue">
        <div id="geolocation-issue">
          <div v-if="geoLocationIssue">
            <table>
              <tr>
                <td>{{ $t("getPermissions.doYouHaveIphone") }}</td>
                <td>
                  <button type="button" class="button is-default" @click="moveToIphoneGps">
                    {{ $t("getPermissions.iphoneGuide") }}
                  </button>
                </td>
              </tr>
              <tr>
                <td>{{ $t("getPermissions.doYouUseChrome") }}</td>
                <td>
                  <button type="button" class="button is-default" @click="moveToChromeGps">
                    {{ $t("getPermissions.chromeGuide") }}
                  </button>
                </td>
              </tr>
              <tr>
                <td>{{ $t("getPermissions.doYouUseFF") }}</td>
                <td>
                  <button type="button" class="button is-default" @click="moveToFirefoxGps">
                    {{ $t("getPermissions.FFGuide") }}
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div v-if="accelerometerIssue">
            <br/><br/>
            <button type="button" class="button is-default" @click="moveToIphoneAccelerometer">
              {{ $t("getPermissions.oldiPhoneAccelerometer") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button class="button is-primary permission-btn" @click="onClick"
    v-if="!inProgress">
      {{ btnText }}
    </button>

    <progress class="progress is-small is-default" max="100" v-if="inProgress">15%</progress><br/>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import * as Sentry from '@sentry/vue'
import preventSleep from '@/preventSleep'

const isFirefox = /^(?!.*Seamonkey)(?=.*Firefox).*/i.test(navigator.userAgent)

export default {
  name: 'GetPermissions',
  props: ['ticketUid'],
  data () {
    return {
      permissions: {},
      inProgress: false,
      gotPermissionsOnce: false,
      errorDuringPermissions: false,
      permissionTexts: {
        geolocation: 'getPermissions.disabledGps',
        camera: 'getPermissions.camera',
        microphone: 'getPermissions.microphone',
        accelerometer: 'getPermissions.accelerometer',
        gyroscope: 'getPermissions.gyroscope'
      },
      askedPermissions: ['geolocation', 'camera', 'accelerometer', 'gyroscope'] // , 'magnetometer', 'speaker', 'microphone'
    }
  },
  computed: {
    missingPermissions () {
      const res = []
      for (const permission of this.askedPermissions) {
        if (!this.permissions[permission]) {
          res.push(permission)
        }
      }
      return res
    },
    allPermissionsOk () {
      return this.missingPermissions.length === 0
    },
    btnText () {
      if (this.gotPermissionsOnce && !this.allPermissionsOk) {
        return this.$t('getPermissions.retry')
      }
      if (this.freshStart) {
        return this.$t('getPermissions.continue')
      } else {
        return this.$t('getPermissions.resume')
      }
    },
    geoLocationIssue () {
      return this.gotPermissionsOnce && !this.permissions.geolocation
    },
    accelerometerIssue () {
      return this.gotPermissionsOnce && !this.permissions.accelerometer
    },
    freshStart () {
      return this.$store.state.mainInfos.freshStart
    }
  },
  methods: {
    moveToIphoneGps () {
      this.$router.push({
        name: 'HelpIphoneGps',
        params: { ticketUid: this.$route.params.ticketUid }
      })
    },
    moveToChromeGps () {
      this.$router.push({
        name: 'HelpChromeGps',
        params: { ticketUid: this.$route.params.ticketUid }
      })
    },
    moveToFirefoxGps () {
      this.$router.push({
        name: 'HelpFirefoxGps',
        params: { ticketUid: this.$route.params.ticketUid }
      })
    },
    moveToIphoneAccelerometer () {
      this.$router.push({
        name: 'IphoneAccelerometer',
        params: { ticketUid: this.$route.params.ticketUid }
      })
    },
    onClick () {
      this.getPermissions()
      preventSleep.enable()
    },
    async getPermissions () {
      this.inProgress = true
      this.errorDuringPermissions = false
      const promises = []
      const comp = this
      // iOS : ask permission for gyroscope
      try {
        if (typeof DeviceOrientationEvent !== 'undefined' && DeviceOrientationEvent.requestPermission !== undefined) {
          console.error('ask gyroscope iOS')
          promises.push(DeviceOrientationEvent.requestPermission().then(function (permissionState) {
            if (permissionState === 'granted') {
              console.error('OK: gyroscope iOS')
              comp.permissions.gyroscope = true
            }
          }).catch(err => console.error(err)))
        } else if (navigator.permissions !== undefined) {
          console.error('ask gyroscope standard')
          // Standard JS API
          promises.push(navigator.permissions.query({ name: 'gyroscope' }).then(function (permission) {
            console.error('OK: gyroscope')
            comp.permissions.gyroscope = permission.state === 'granted'
          }).catch(function (error) {
            if (isFirefox && error.message.includes('is not a valid value for enumeration PermissionName')) {
              console.error('FIREFOX : gyroscope is enabled by default')
              comp.permissions.gyroscope = true
            }
          }))
        } else {
          console.error('gyroscope - Fallback (iOS 12-)')
          // Fallback : we just test if gyroscope works fine
          // Otherwise, it requires the user to activate it in the settings
          let gyroscopeOk = false
          const receivedGyroscope = function (evt) {
            console.error('receivedGyroscope')
            console.error(evt.alpha)
            if (evt.alpha !== null && evt.gamma !== null && evt.gamma !== null) {
              gyroscopeOk = true
            }
          }
          window.addEventListener('deviceorientation', receivedGyroscope)
          promises.push(
            new Promise((resolve, reject) => {
              setTimeout(function () {
                console.error('500ms elapsed')
                console.error(gyroscopeOk)
                // Clear
                window.removeEventListener('deviceorientation', receivedGyroscope)
                // Has the gyroscope sent data in the last second ?
                comp.permissions.gyroscope = gyroscopeOk
                // Validate promise
                resolve(true)
              }, 1000)
            })
          )
        }

        // iOS : ask permission for accelerometer
        if (typeof DeviceMotionEvent !== 'undefined' && DeviceMotionEvent.requestPermission !== undefined) {
          console.error('ask accelerometer iOS')
          promises.push(DeviceMotionEvent.requestPermission().then(function (permissionState) {
            if (permissionState === 'granted') {
              console.error('OK: accelerometer iOS')
              comp.permissions.accelerometer = true
            }
          }).catch(err => console.error(err)))
        } else if (navigator.permissions !== undefined) {
          console.error('ask accelerometer standard')
          // Standard JS API
          promises.push(navigator.permissions.query({ name: 'accelerometer' }).then(function (permission) {
            console.error('OK: accelerometer')
            comp.permissions.accelerometer = permission.state === 'granted'
          }).catch(function (error) {
            if (isFirefox && error.message.includes('is not a valid value for enumeration PermissionName')) {
              console.error('FIREFOX : accelerometer is enabled by default')
              comp.permissions.accelerometer = true
            }
          }))
        } else {
          console.error('accelerometer - Fallback (iOS 12-)')
          // Fallback : we just test if accelerometer works fine
          // Otherwise, it requires the user to activate it in the settings
          let accelerometerOk = false
          const receivedAccelerometer = function (evt) {
            console.error('receivedAccelerometer')
            console.error(evt.acceleration)
            if (evt.acceleration !== null) {
              accelerometerOk = true
            }
          }
          window.addEventListener('devicemotion', receivedAccelerometer)
          promises.push(
            new Promise((resolve, reject) => {
              setTimeout(function () {
                console.error('500ms elapsed')
                console.error(accelerometerOk)
                // Clear
                window.removeEventListener('devicemotion', receivedAccelerometer)
                // Has the accelerometer sent data in the last second ?
                comp.permissions.accelerometer = accelerometerOk
                // Validate promise
                resolve(true)
              }, 1000)
            })
          )
        }

        console.error('ask GPS')
        // GPS
        const gpsPromise = new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(function () {
            console.error('OK: GPS')
            comp.permissions.geolocation = true
            resolve(true)
          }, function (error) {
            console.error('FAILED: GPS')
            console.error(error)
            Sentry.captureMessage('GPS : ' + error.message)
            reject(error)
          },
          {
            timeout: 10000, // Don't wait more than 10s to get a signal
            enableHighAccuracy: true
          })
        })
        // As the timeout doesn't seem to work on iOS (...), let's do it ourselves
        const gpsTimeoutPromise = new Promise((resolve, reject) => {
          const wait = setTimeout(() => {
            clearTimeout(wait)
            reject(new Error('GPS Location has timed out'))
          }, 11000)
        })

        promises.push(Promise.race([gpsPromise, gpsTimeoutPromise]))

        console.error('ask camera')
        // Camera & microphone
        if (typeof navigator.mediaDevices !== 'undefined') {
          promises.push(navigator.mediaDevices.getUserMedia({
            video: true,
            audio: false
          }).then(function (stream) {
            console.error('OK: camera')
            comp.permissions.camera = true
            // Close streams
            stream.getTracks().forEach(function (track) {
              track.stop()
            })
            // comp.permissions.microphone = true
          }))
        }

        // Initiate sound (iOs 14+ compatibility)
        this.$store.commit('IOS_INIT')
      } catch (error) {
        Sentry.captureException(error)
        console.error(error)
        this.errorDuringPermissions = true
      }

      const res = await Promise.all(promises).catch(function (err) {
        console.error('failure', err)
      })
      console.error('got all promises', res)

      this.gotPermissionsOnce = true
      this.inProgress = false
      console.error('over')
      if (this.allPermissionsOk && this.gotPermissionsOnce) {
        if (screenfull.isEnabled) {
          screenfull.request()
        }

        this.$store.commit('SET_PERMISSIONS_OK')
        this.$emit('permissionsOk')
      }
    }
  }
}
</script>

<style scoped lang="sass">
  .permission-btn
    margin: 20px 0px
  .permissions-missing
    max-width: 300px
    margin: auto
    margin-top: 20px
    text-align: left
    ul
      li
        padding-bottom: 10px
  .progress
    width: 200px
    margin: auto
    margin-top: 5px

  .geolocation-notification
    padding: 1.25rem 1rem 1.25rem 0.5rem

  #geolocation-issue
    text-align: center

    table
      width: 100%
      td
        text-align: left
        padding: 5px
        .button
          font-size: 14px
          padding: 5px 10px

  .button.is-primary
    background-color: #999
</style>
