<template>
  <div class="modal" :class="{'is-active': displayed}" id="rankings-modal">
    <div class="modal-background"></div>
    <div class="modal-content">
      <RankingsContent></RankingsContent>
    </div>

    <button
      class="modal-close is-large"
      aria-label="close"
      @click="closeModal">
    </button>
  </div>
</template>

<script>
import RankingsContent from '@/components/rankings/RankingsContent'

export default {
  name: 'RankingsModal',
  components: { RankingsContent },
  props: ['displayed'],
  methods: {
    closeModal () {
      this.$emit('update:displayed', false)
    }
  }
}
</script>

<style scoped lang="sass">
  .modal
    z-index: 110

  .modal-background
    background-color: rgba(255, 255, 255, 0.95)

  .modal-close::before, .modal-close::after
    background-color: black

</style>
