import { createI18n } from 'vue-i18n'
import enTranslations from './translations/en.json'
import frTranslations from './translations/fr.json'

console.log('Using locale', navigator.language.split('-')[0])
const i18n = createI18n({
  locale: navigator.language.split('-')[0], // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    fr: frTranslations,
    en: enTranslations
  }
})

export default i18n
