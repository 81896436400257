function formatElapsedTime (value, shorten) {
  const seconds = Math.floor(value % 60)
  const minutes = Math.floor(value / 60) % 60
  const hours = Math.floor(value / 3600)
  const minSecs = minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
  if (shorten && hours === 0) {
    return minSecs
  }
  return hours.toString().padStart(2, '0') + ':' + minSecs
}

export {
  formatElapsedTime
}
