<template>
  <div class="help-permissions">
    <button type="button" class="button is-default" @click="move_back_to_ticket()">
      <i class="fa fa-chevron-left"></i>&nbsp; {{ $t("common.goBack") }}
    </button><br/><br/>

    {{ $t("help.followProcedure") }}<br/><br/>

    <ol>
      <li>
        <b>{{ $t("help.chrome.procedure1") }}</b>
        <img src="/img/help/chrome/chrome-gps-permissions.webp"/>
      </li>
      <li>
        <b>{{ $t("help.chrome.procedure2") }}</b>
      </li>
    </ol>

    <div class="go-back-to-adventures">
      {{ $t("help.onceDone") }}<br/><br/>

      <button type="button" class="button is-success" @click="move_back_to_ticket()">
        {{ $t("help.backToAdventure") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpFirefoxGps',
  methods: {
    move_back_to_ticket () {
      this.$router.push({
        name: 'Ticket',
        params: { ticketUid: this.$route.params.ticketUid }
      })
    }
  }
}
</script>

<style lang="sass">
  .help-permissions
    padding: 40px 30px
    text-align: left
    font-size: 16px

    ol
      padding-left: 15px
      li
        margin-bottom: 60px
        b, img
          display: block
        b
          padding-bottom: 30px
        img
          max-width: 100%

    .go-back-to-adventures
      margin-top: 80px
      text-align: center

      button.button
        margin-bottom: 40px
        margin-top: 20px

</style>
