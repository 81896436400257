<template>
  <div>
    <div class="notification is-warning" v-if="rankings.length > 0">
      <span v-if="!somebodyFinished">
        {{ $t("rankings.scoreExplanation1") }}<br/><br/>
        {{ $t("rankings.scoreExplanation2") }}
      </span>
      <span v-if="somebodyFinished && !everyoneFinished">
        {{ $t("rankings.stillInProgress1") }}<br/>
        {{ $t("rankings.stillInProgress2") }}
        <br/><br/>
        {{ $t("rankings.stillInProgress3") }}
      </span>
      <span v-if="everyoneFinished">
        {{ $t("rankings.finalRanking") }}<br/>
        {{ $t("rankings.congrats") }}
      </span>

    </div>

    <img
      src="/img/rankings/dynamic-top.png"
      v-if="showImages"
      id="dyn-ranking-top"
      class="rankings-bgimage"
    />
    <table class="table" v-if="rankings.length > 0" :class="{'with-bgimage': showImages}">
      <tr>
        <th v-if="showRanks"></th>
        <th>{{ $t("rankings.colTeam") }}</th>
        <th><abbr title="Progression">%</abbr></th>
        <th><abbr title="Total temps passé">{{ $t("rankings.colTime") }}</abbr></th>
        <th>{{ $t("rankings.colBonus") }}</th>
        <th>{{ $t("rankings.colMalus") }}</th>
      </tr>
      <tr
          v-for="(ranking,index) in rankings"
          :key="ranking.teamName"
          :class="{'is-selected': ranking.ticketUid === ticketUid}"
      >
        <th v-if="showRanks">
          <span v-if="ranking.progress === 100">{{ index + 1 }}</span>
        </th>
        <th>{{ ranking.teamName }}</th>
        <td>{{ ranking.progress }}%</td>
        <td>{{ formatTime(ranking.totalTimeElapsed) }}</td>
        <td>{{ formatTime(ranking.bonuses) }}</td>
        <td>{{ formatTime(ranking.penalties) }}</td>
      </tr>
    </table>
    <img src="/img/rankings/dynamic-bottom.png" v-if="showImages" class="rankings-bgimage"/>
  </div>
</template>

<script>
import { formatElapsedTime } from '@/functions/time'

export default {
  name: 'RankingsContent',
  props: {
    showImages: {
      type: Boolean,
      default: false
    },
    showRanks: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatTime (value) {
      if (value === 0) {
        return '-'
      }
      return formatElapsedTime(value, true)
    }
  },
  computed: {
    rankings () {
      return this.$store.state.gathering.rankings
    },
    somebodyFinished () {
      return this.rankings[0].progress === 100
    },
    everyoneFinished () {
      return this.rankings[this.rankings.length - 1].progress === 100
    },
    ticketUid () {
      return this.$store.state.ticketUid
    }
  }
}
</script>

<style scoped lang="sass">
  .notification
    font-size: 14px

  table
    width: 100%
    background-color: transparent
    tr.is-selected
      background-color: #777
    th:not(:nth-child(2))
      text-align: center
    th, td
      padding: 5px 8px

    @media (max-width: 450px)
      word-break: break-word
      &.with-bgimage
        width: 80%
        box-sizing: content-box
        padding: 0px 10%
        display: block
        th, td
          padding: 3px 5px
      th, td
        font-size: 12px

    @media (min-width: 451px)
      &.with-bgimage
        padding: 0px 10%
      th, td
        font-size: 14px

    &.with-bgimage
      margin-top: -5px
      margin-bottom: -2px
      background-image: url('@/../public/img/rankings/dynamic-middle.png')
      background-repeat: repeat no-repeat
      background-size: 100% auto
      background-clip: border-box
      border-color: transparent !important
      tr, td, th
        border: none !important

  .rankings-bgimage
    width: 100%
    z-index: 100

  #dyn-ranking-top
    z-index: 99

</style>
