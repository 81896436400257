<template>
  <div>
    <div
      v-for="lang in availableLanguages"
      :key="lang.value"
      class="lang-block"
      :class="{ 'selected': selectedLanguage === lang.value }"
      @click="selectLang(lang)"
    >
      <img :src="'/img/flags/' + lang.image"/>
    </div>
    <div id="only-one-language" v-if="availableLanguages.length === 1">
      Ce parcours n'est disponible qu'en une seule langue<br/><br/>
      This journey is only available in one language<br/><br/>
      <button class="button is-default" @click="selectLang(availableLanguages[0])">
        {{ $t("common.continue") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageChoice',
  props: ['ticketUid'],
  data () {
    return {
      potentialLanguages: [
        {
          value: 'fr',
          image: 'fr.png'
        },
        {
          value: 'en',
          image: 'en.png'
        }
      ],
      selectedLanguage: null
    }
  },
  async created () {
    const currentLanguage = this.$store.state.mainInfos.language
    if (!currentLanguage) {
      return // Failed
    }
    this.selectedLanguage = currentLanguage
  },
  computed: {
    availableLanguages () {
      if (!this.$store.state.mainInfos || Object.keys(this.$store.state.mainInfos).length === 0) {
        return []
      }
      console.log('mainInfos', JSON.stringify(this.$store.state.mainInfos))
      const res = []
      for (const lang of this.potentialLanguages) {
        if (this.$store.state.mainInfos.availableLanguages.includes(lang.value)) {
          res.push(lang)
        }
      }
      return res
    }
  },
  methods: {
    async selectLang (lang) {
      this.selectedLanguage = lang.value
      this.$emit('languageSelected', this.selectedLanguage)
    }
  }
}
</script>

<style scoped lang="sass">
  .lang-block
    width: 100px
    display: inline-block
    padding: 20px
    img
      width: 100%
      height: 67px
      cursor: pointer
    &.selected
      img
        border: solid 4px #999

</style>
