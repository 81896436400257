import axios from 'axios'
import axiosRetry from 'axios-retry'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_HOST + '/api'
})

axiosRetry(axiosInstance,
  {
    retries: 100000,
    retryDelay: (retryCount) => { return 1000 },
    retryCondition: (err) => {
      return !err.response
    },
    shouldResetTimeout: true
  })

export default axiosInstance
