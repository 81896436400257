<template>
  <div id="shaking-detector">
  </div>
</template>

<script>
import Shake from '@/external/shake'

export default {
  name: 'SceneShakingDetector',
  props: ['attributes', 'number'],
  data () {
    return {
      shakeDetector: null,
      validated: false
    }
  },
  mounted () {
    // create a new instance of shake.js.
    this.shakeDetector = new Shake({
      threshold: 10
    })

    // start listening to device motion
    this.shakeDetector.start()

    // register a shake event
    window.addEventListener('shake', this.onShake, false)
  },
  unmounted () {
    // window.removeEventListener('keydown', this.onKeydown)
    this.shakeDetector.stop()
    window.removeEventListener('shake', this.onShake)
  },
  methods: {
    onShake () {
      if (this.validated) {
        return
      }
      this.validated = true
      this.$store.dispatch('validateItem', {
        number: this.number
      })
      if (this.attributes.sound) {
        this.playSound()
      }
    },
    playSound () {
      const track = this.$store.state.audio.tracks[2]

      track.volume = 1
      track.src = this.attributes.sound
    },
    onKeydown (evt) {
      evt.preventDefault()
    }
  }
}
</script>

<style scoped lang="sass">
  #shaking-detector
    width: 100%
    height: 40px
</style>
