<template>
  <div class="columns is-mobile">
    <div class="column is-narrow">
      <div
        class="button-wrapper"
        @touchstart="moveBefore()"
        :class="{'hidden-fa': !hasEntriesBefore}">
        <i class="fas fa-chevron-left previous">
        </i>
      </div>
    </div>
    <div class="column main-column">
      <img
        :src="currentEntry.url"
        class="image-entry"
      />
      <button
        class="button"
        @click="tapImage(currentEntry.key)"
      >
        {{ $t("common.validate") }}
      </button>
      <p
        class="notification is-danger"
        v-if="invalidAnswer">
        {{ $t("common.nope") }}
        <span v-if="attributes.penalty">
          <br/>
          ({{ $t("common.penalty") }} + {{ attributes.penalty }}s)
        </span>
      </p>
    </div>
    <div class="column is-narrow">
      <div
          class="button-wrapper"
          @touchstart="moveNext()"
          :class="{'hidden-fa': !hasEntriesAfter}">
        <i class="fas fa-chevron-right next">
        </i>
      </div>
    </div>
  </div>
</template>

<script>

import PuzzleMixin from '@/mixins/Puzzle'

export default {
  name: 'SceneImageChoice',
  mixins: [PuzzleMixin],
  props: ['attributes', 'number'],
  data () {
    return {
      invalidAnswer: false,
      entryNo: 0
    }
  },
  computed: {
    hasEntriesBefore () {
      return this.entryNo >= 1
    },
    hasEntriesAfter () {
      return this.entryNo < this.attributes.items.length - 1
    },
    currentEntry () {
      return this.attributes.items[this.entryNo]
    }
  },
  methods: {
    moveNext () {
      if (!this.hasEntriesAfter) {
        return
      }
      this.entryNo += 1
      this.invalidAnswer = false
    },
    moveBefore () {
      if (!this.hasEntriesBefore) {
        return
      }
      this.entryNo -= 1
      this.invalidAnswer = false
    },
    tapImage (value) {
      if (value === this.attributes.expectedValue) {
        // Success
        this.playVictorySound()
        this.$store.dispatch('validateItem', {
          number: this.number
        })
      } else {
        // Time penalty
        this.invalidAnswer = true
        this.$store.dispatch('addTimePenalty', {
          seconds: this.attributes.penalty
        })
        this.playFailedSound()
      }
    }
  }
}
</script>

<style scoped lang="sass">
  .columns
    margin: 0

  .image-entry
    width: 100%

  .button
    margin-top: 30px

  .button-wrapper
    margin-top: 20px
    &.hidden-fa
      svg
        visibility: hidden

  .previous, .next
    font-size: 26px
    font-weight: bold
    cursor: pointer

  .previous
    margin-left: 15px

  .next
    margin-right: 15px

</style>
