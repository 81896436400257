<template>
  <div id="layout-main-top-wrapper">
    <div class="columns first-row is-mobile scrollable-zone" :style="firstRowStyle">
      <div class="column is-12">
        <Zone name="main"></Zone>
      </div>
    </div>
    <div class="columns second-row is-mobile scrollable-zone" :style="secondRowStyle">
      <div class="column is-s6">
        <Zone name="left"></Zone>
      </div>
      <div class="column is-s6">
        <Zone name="right"></Zone>
      </div>
    </div>
  </div>
</template>

<script>
import Zone from '@/components/Zone'

export default {
  name: 'LayoutMaintop',
  components: { Zone },
  computed: {
    firstRowStyle () {
      let percentage = this.$store.state.layout.attributes.mainHeightPercentage
      if (!percentage) {
        percentage = 50
      }
      return 'height: ' + percentage.toString() + '%'
    },
    secondRowStyle () {
      let percentage = this.$store.state.layout.attributes.mainHeightPercentage
      if (!percentage) {
        percentage = 50
      } else {
        percentage = 100 - percentage
      }
      return 'height: ' + percentage.toString() + '%'
    }
  }
}
</script>

<style scoped lang="sass">
  #layout-main-top-wrapper
    position: fixed
    width: 100%
    height: 100%

  .second-row
    position: fixed
    bottom: 0
    left: 0
    right: 0
    overflow: hidden
    margin-bottom: 0px !important

  .first-row
    overflow: auto
    .column
      padding-top: 60px
      box-sizing: border-box

  .column
    height: 100%
    padding: 0

  .columns
    margin: 0px
</style>
