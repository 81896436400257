<template>
  <div>
  </div>
</template>

<script>
import GpsSceneWithTargetMixin from '@/mixins/GpsSceneWithTarget'
export default {
  name: 'SayHiBasic',
  mixins: [GpsSceneWithTargetMixin],
  props: ['attributes', 'number'],
  data () {
    return {
      cumulativeX: 0,
      isValidated: false
    }
  },
  mounted () {
    window.addEventListener('devicemotion', this.onDeviceMotion)
  },
  unmounted () {
    window.removeEventListener('devicemotion', this.onDeviceMotion)
  },
  methods: {
    onDeviceMotion (evt) {
      const current = evt.acceleration
      const treshold = 10
      if (current.x > treshold) {
        this.cumulativeX += current.x
      }
      if (this.cumulativeX > 500) {
        this.validate()
        this.isValidated = true
      }
    },
    onDistanceUpdate (value) {
    },
    validate () {
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    }
  }
}
</script>
