<template>
  <div>
    <div class="notification is-default">
      {{ attributes.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SceneRawText',
  props: ['attributes']
}
</script>

<style lang="sass" scoped>
  .notification
    white-space: pre-line
    padding: 20px 25px 30px 25px
    margin-bottom: 10px
    margin-top: 10px
</style>
