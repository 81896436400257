<template>
  <div class="rotation-container" ref="container" :style="containerStyle">
    <div id="not-full-screen" v-if="isFullScreen === false">
      {{ $t("common.foFullScreen") }}
      <br/><br/>
      <button class="button is-default" @click="enableFullScreen">
        {{ $t("common.foFullScreenAction") }}
      </button>
    </div>
    <img
      v-if="gyroscopeEnabled"
      :src="slideToDisplay.imageUrl"/>
    <div
      class="gyroscope-disabled notification notificaton-info"
      v-if="gyroscopeEnabled === false"
    >
      {{ $t("scene.rotate.noGyroscope1") }}<br/><br/>
      {{ $t("scene.rotate.noGyroscope2") }}<br/><br/>

      <button class="button is-default not-working-button" @click="validate">
        {{ $t("scene.rotate.noGyroscope3") }}<br/>{{ $t("scene.rotate.noGyroscope4") }}
      </button>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import isSafari from '@/functions/safari'

export default {
  name: 'SceneRotateAnimation',
  props: ['attributes', 'number'],
  data () {
    return {
      gyroscopeEnabled: null,
      rotation: 0,
      previousAlpha: null,
      isFullScreen: null,
      isSafari: isSafari(),
      animationSuccess: false,
      containerHeight: null,
      containerHeightLandscape: null,
      containerStyle: {}
    }
  },
  mounted () {
    this.isFullScreen = screenfull.isFullscreen
    if (this.isFullScreen === undefined) {
      // Safari / iOS
      this.tryEnableGyroscope()
      return
    }
    if (this.isFullScreen) {
      this.tryEnableGyroscope()
    }
  },
  unmounted () {
    window.removeEventListener('deviceorientation', this.onDeviceOrientation)
    if (this.isSafari || screen.orientation.unlock === undefined) {
      this.$store.commit('FORCE_IOS_SCREEN_ORIENTATION', null)
    } else {
      screen.orientation.unlock()
    }
  },
  computed: {
    slideToDisplay () {
      let slide = null
      let direction = 1
      if (this.attributes.direction === 'counter-clockwise') {
        direction = -1
      }
      for (slide of this.attributes.animation) {
        if (this.rotation * direction <= slide.angle) {
          break
        }
      }
      return slide
    },
    audioTrack () {
      return this.$store.state.audio.tracks[1]
    }
  },
  watch: {
    slideToDisplay (value) {
      // When slide changes, and there is a sound attached to it, play the sound
      if (!value.soundUrl) {
        return
      }
      this.audioTrack.volume = 1
      this.audioTrack.src = value.soundUrl
    }
  },
  methods: {
    calculateHeights () {
      const parent = this.$refs.container.closest('.scrollable-zone')
      if (parent !== null) {
        const component = this
        setTimeout(function () {
          if (!component.isSafari || window.innerHeight > window.innerWidth) {
            component.containerHeight = parent.clientHeight - 10
            component.containerHeightLandscape = parent.clientWidth - 10
          } else {
            component.containerHeight = parent.clientWidth - 10
            component.containerHeightLandscape = parent.clientHeight - 10
          }
          component.refreshContainerStyle()
        }, 1000)
      }
    },
    refreshContainerStyle () {
      this.containerStyle = {}
      if (this.containerHeight !== null) {
        if (window.innerHeight > window.innerWidth) {
          this.containerStyle = {
            'max-height': this.containerHeight.toString() + 'px'
          }
        } else {
          this.containerStyle = {
            'max-height': this.containerHeightLandscape.toString() + 'px'
          }
        }
      }
    },
    enableFullScreen () {
      console.log('enableFullScreen')
      const comp = this
      screenfull.on('change', () => {
        if (screenfull.isFullscreen) {
          comp.isFullScreen = true
          comp.tryEnableGyroscope()
        }
      })
      screenfull.request()
    },
    tryEnableGyroscope () {
      this.calculateHeights()
      console.log('tryEnableGyroscope')
      // Lock screen display
      if (!this.isSafari && screen.orientation.lock !== undefined) {
        screen.orientation.lock('portrait')
      } else {
        this.$store.commit('FORCE_IOS_SCREEN_ORIENTATION', 'portrait')
      }

      // Get permission for accessing gyroscope and bind events
      if (typeof DeviceOrientationEvent.requestPermission === 'function') {
        console.log('ask permission')
        DeviceOrientationEvent.requestPermission().then(this.startListeningGyroscope).catch(this.cannotAccessGyroscope)
        return
      }
      if (!window.DeviceOrientationEvent || !('ontouchstart' in window)) {
        console.log('cannotAccessGyroscope')
        this.cannotAccessGyroscope()
      } else {
        console.log('startListeningGyroscope')
        this.startListeningGyroscope()
      }
    },
    startListeningGyroscope () {
      window.addEventListener('deviceorientation', this.onDeviceOrientation)
    },
    cannotAccessGyroscope () {
      this.gyroscopeEnabled = false
    },
    onDeviceOrientation (evt) {
      this.refreshContainerStyle()
      if (this.animationSuccess) {
        // Already successful
        return
      }
      if (evt.alpha !== null && evt.gamma !== null && evt.gamma !== null) {
        this.gyroscopeEnabled = true
      } else {
        this.gyroscopeEnabled = false
        return
      }

      if (this.previousAlpha === null) {
        this.previousAlpha = evt.alpha
        return
      }

      let angle = 0
      if (this.previousAlpha > 300 && evt.alpha < 100) {
        // Going clockwise
        angle = 360 - this.previousAlpha + evt.alpha
      } else if (this.previousAlpha < 100 && evt.alpha > 300) {
        // Going counterclockwise (negative)
        angle = -this.previousAlpha - 360 + evt.alpha
      } else {
        // Not going around 0
        angle = evt.alpha - this.previousAlpha
      }

      if (evt.beta > 90 || evt.beta < -90) {
        // When the mobile phone is oriented a certain way around beta, alpha is negated
        angle = -angle
      }

      // console.log(this.previousAlpha, '->', evt.alpha, ' ===== ', angle)

      if (Math.abs(angle) > 100) {
        // Security
        // console.log('XXX', this.previousAlpha, evt.alpha, angle)
        this.previousAlpha = evt.alpha
        return
      }

      if (this.attributes.direction === 'counter-clockwise') {
        this.rotation -= Math.round(angle)
        if (this.rotation > 100) {
          this.rotation = 100
        }
      }
      if (this.attributes.direction === 'clockwise') {
        this.rotation -= Math.round(angle)
        if (this.rotation < -100) {
          this.rotation = -100
        }
      }
      // console.log('   [[[ ', this.rotation, ' ]]]')
      this.previousAlpha = evt.alpha
      this.updateSuccess()
    },
    validate () {
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    },
    // Are we in a successful state ?
    updateSuccess () {
      if (this.animationSuccess) {
        // Already successful
        return
      }
      const lastSlideIndex = this.attributes.animation.length - 1
      if (this.slideToDisplay.imageUrl === this.attributes.animation[lastSlideIndex].imageUrl) {
        if (this.attributes.autoValidateAtEnd) {
          this.animationSuccess = true
          setTimeout(this.validate, 4000)
        }
      }
    },
    onDisplaySuccessButton (el) {
      setTimeout(function () {
        el.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }
}
</script>

<style scoped lang="sass">
  img
    max-width: 100%
    max-height: 100%
    display: block
  .not-working-button
    padding: 35px 30px
  .rotation-container
    display: flex
    max-height: 100%
    height: 100%
</style>
