<template>
  <div class="wrapper">
    <p
      class="notification is-danger"
      v-if="invalidMessage && validityChecked">
        {{ invalidMessage }}
    </p>
    <div v-if="expectedValue">
      <div class="field is-grouped word"
        v-for="word in words"
        :key="word.startIndex">
        <span v-for="(expectedChar, ichar) of word.value" :key="ichar" class="char-container">
          <FakeInputText
            :maxlength="1"
            :ref="'char' + (word.startIndex + ichar).toString()"
            @update:modelValue="onEnterChar(word.startIndex + ichar, $event)"
            :modelValue="textValue[word.startIndex + ichar]"
            :getFocus="word.startIndex + ichar === currentChar">
          </FakeInputText>
        </span>
      </div>
    </div>
    <p
      class="notification is-danger"
      :ref="onDisplayErrorButton"
      v-if="wrongValue">
      {{ $t("common.nope") }}
    </p>
    <div
      class="button is-default"
      v-if="isValid && expectedValue"
      @click="validate"
      :ref="onDisplaySuccessButton">
      {{ $t("common.congrats") }}&nbsp;<i class="fa fa-angle-right"></i>
    </div>
    <img
      :src="currentEntry.url"
      class="image-entry"
    />
  </div>
</template>

<script>

import FakeInputText from '@/components/FakeInputText'
import PuzzleMixin from '@/mixins/Puzzle'
import TextInputMixin from '@/mixins/TextInput'

export default {
  name: 'SceneImagesToLegend',
  mixins: [PuzzleMixin, TextInputMixin],
  components: { FakeInputText },
  props: ['attributes', 'number'],
  data () {
    return {
      entryNo: 0
    }
  },
  created () {
    this.initParameters()
  },
  computed: {
    currentEntry () {
      return this.attributes.items[this.entryNo]
    },
    expectedValue () {
      return this.currentEntry.legend
    },
    dashed () {
      return true
    }
  },
  methods: {
    async initParameters () {
      // Initialize textValue with spaces
      this.textValue = ' '.repeat(this.expectedValue.length)
      this.wrongValue = false
      this.isValid = false
      this.currentChar = 0
      this.invalidMessage = false
      this.validityChecked = false
    },
    onWrongValue () {
      // No specific event on wrong value
    },
    async whenValid () {
      this.playVictorySound()
      // When valid, switch to next item
      // Or validate puzzle if this is the last image
      if (this.entryNo === this.attributes.items.length - 1) {
        this.$store.dispatch('validateItem', {
          number: this.number
        })
      } else {
        // Next item
        this.entryNo += 1
        // Reset input
        this.initParameters()
      }
    }
  }
}
</script>

<style scoped lang="sass">
  .wrapper
    margin-top: 85px

  .image-entry
    width: 100%

  .field
    max-width: 100%
    margin: auto
    display: flex

  .notification
    width: 200px
    margin: auto
    margin-top: 15px
    padding: 10px

  .button.is-default
    margin-top: 20px
    margin-bottom: 30px

  .word
    margin: 20px 20px 0px 20px
    justify-content: center !important
    max-width: none

    .char-container
      text-align: center
      max-width: 50px
      input
        text-align: center
        text-transform: uppercase
        font-weight: bold
        padding: 5px 0px
        width: 80%

</style>
