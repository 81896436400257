<template>
  <div>
    <div
      class="image-container"
      :style="imageContainerStyle"
    >
      <img
        class="background-image"
        :src="imageUrl"
        @load="onImageLoad"/>

      <div
        v-for="(input, index) in attributes.inputs"
        :key="index"
        @click="switchInput(index)"
        type="text"
        class="input-to-fill"
        :style="inputStyles[index]"
      >
        {{ currentInputs[index] }}
      </div>
    </div>
    <div
      class="button is-default"
      v-if="isCorrect"
      @click="validate"
      :ref="onDisplaySuccessButton">
      {{ $t("common.congrats") }}&nbsp;<i class="fa fa-angle-right"></i>
    </div>
  </div>
</template>

<script>
import PuzzleMixin from '@/mixins/Puzzle'

export default {
  name: 'SceneInputsOnImage',
  mixins: [PuzzleMixin],
  props: ['attributes', 'number'],
  data () {
    return {
      imageWidth: 0,
      imageRatio: 1, // Ratio displayed comparing to original size (background)
      currentInputs: {}, // inputIndex: content
      inputClicked: {} // boolean true : has the user clicked once on it ?
    }
  },
  created () {
    for (const [index, inputData] of this.attributes.inputs.entries()) {
      this.currentInputs[index] = inputData.choices[0] // First choice at start
    }
  },
  computed: {
    inputStyles () {
      const res = []
      for (const [index, inputData] of this.attributes.inputs.entries()) {
        const x = this.imageRatio * inputData.x
        const y = this.imageRatio * inputData.y

        const style = {
          left: x.toString() + 'px',
          top: y.toString() + 'px',
          width: inputData.width.toString() + 'px',
          height: inputData.height.toString() + 'px',
          transform: 'scale(' + this.imageRatio.toString() + ')',
          'transform-origin': 'top left'
        }
        if (!this.inputClicked[index]) {
          style.color = 'red'
        }
        if (this.isCorrect || this.imageWidth === 0) {
          style.display = 'none'
        }
        res.push(style)
      }
      return res
    },
    // Image container style: set the image width once loaded
    imageContainerStyle () {
      if (this.imageWidth === 0) {
        return {}
      }
      return {
        width: this.imageWidth.toString() + 'px'
      }
    },
    // Is the puzzle solved?
    isCorrect () {
      for (const [index, inputData] of this.attributes.inputs.entries()) {
        if (this.currentInputs[index] !== inputData.expected) {
          return false
        }
      }
      return true
    },
    // Image to display : base, or success?
    imageUrl () {
      if (this.isCorrect && this.attributes.successUrl) {
        return this.attributes.successUrl
      }
      return this.attributes.url
    }
  },
  methods: {
    // On loading image, retrieve the display scale ratio
    onImageLoad (evt) {
      this.imageRatio = evt.target.width / evt.target.naturalWidth
      this.imageWidth = evt.target.width
    },
    // Turn input to next char
    switchInput (index) {
      this.inputClicked[index] = true
      const currentValue = this.currentInputs[index]
      const choices = this.attributes.inputs[index].choices

      let i = choices.indexOf(currentValue)
      if (i === choices.length - 1) {
        i = 0
      } else {
        i += 1
      }
      this.currentInputs[index] = choices[i]
    },
    onDisplaySuccessButton (el) {
      if (el) {
        this.playVictorySound()
      }
      setTimeout(function () {
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    },
    validate () {
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    }
  }
}
</script>

<style scoped lang="sass">
  .image-container
    margin: auto
    margin-top: 20px
    position: relative
    .background-image
      display: block
      max-width: 100%
    img
      user-select: none

    .input-to-fill
      position: absolute
      font-weight: bold
      display: flex !important
      justify-content: center
      align-items: center
      user-select: none
      cursor: pointer
      font-size: 30px
      padding-top: 3px
      color: rgba(220, 92, 0)

  .button.is-default
    margin: 0px 0px

</style>
