<template>
  <div>
    <div class="field is-grouped">
      <p class="control is-expanded">
        <FakeInputText
          :placeholder="attributes.placeholder"
          :modelValue="textValue"
          type="number"
          @update:modelValue="onChangeText">
        </FakeInputText><br/>
      </p>
      <p class="control">
        <a class="button is-info" @click="validate">
          <span class="icon is-small">
            <i class="fas fa-check"></i>
          </span>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import FakeInputText from '@/components/FakeInputText'

export default {
  name: 'SceneNumberInput',
  props: ['attributes', 'number'],
  components: { FakeInputText },
  data () {
    return {
      textValue: ''
    }
  },
  methods: {
    onChangeText (value) {
      this.textValue = value
    },
    isValidNumber (value) {
      return !isNaN(value) && !isNaN(parseFloat(value))
    },
    async validate () {
      if (this.textValue.length > 0) {
        if (!this.isValidNumber(this.textValue)) {
          console.log('not a number')
          return
        }
        if (this.attributes.key) {
          await this.$store.dispatch('storeData', {
            key: this.attributes.key,
            value: this.textValue
          })
        }
        this.$store.dispatch('validateItem', {
          number: this.number
        })
      }
    }
  }
}
</script>

<style scoped lang="sass">
  .field
    max-width: 300px
    margin: auto

  .notification
    width: 200px
    margin: auto
    margin-top: 15px
    padding: 10px

  .button.is-default
    margin-top: 20px
    margin-bottom: 30px

  .word
    margin: 20px 20px 0px 20px
    justify-content: center !important
    max-width: none

    .char-container
      text-align: center
      margin-right: 2px
      input
        text-align: center
        width: 20px
        text-transform: uppercase
        font-weight: bold

</style>
